import styled from 'styled-components';

export const Style = styled.div`
    overflow-x: ellipsis;

.welcome-elements {
    padding: 10%;
    .contain {
        position: relative;
        width: 100%;

        .container {
            display: flex;
            text-align: left;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 4em;

            .details {
                width: 50%;
                height: 100px;
                svg{
                    padding:50px;
                }
            }
        }

        .welcome-sub-heading {
            font-size: 1.5em;
            color: #273883;
        }


        .welcome-heading {
            padding-top:  0;
            font-size: 3em;
            font-family: "Montserrat";
            font-weight: 600;
            margin-bottom: 0.3em;
        }
    }
}



`