import styled from 'styled-components';

export const Style = styled.div`
    overflow-x: hidden;

.welcome-elements {
    padding: 5%;
    .contain {
        padding: 5%;
        .welcome-heading {
            padding-top:  0;
            font-size: 3em;
            font-family: "Montserrat";
            font-weight: 600;
            text-align: center;
        }
    }
}

hr {
    width: 40%;
    left: 30%;
    border: none;
    background: #53BAE7;
    height: 3px;
    margin-bottom: 6em;
}



.container{
    padding: 3%;
    margin-bottom: 5%;
        .gallery-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: auto;
        }
}

`
export const StyleCard = styled.div`
    display: flex;
    
    .contain-cards {
        margin: 2em;
        padding:3%;
        max-width: 550px;
        display: flex;
        

        @media screen and (max-width:1200px){
            width: 350px;
        } 

        .image-name-container{
            display: flex;
            flex-direction: column;

            img {
                width:  200px;
                height: 200px;
                object-position: 50% 50%;
                object-fit: cover;
                filter: grayscale(1);
                :hover {
                    filter: grayscale(0);
                }
            }
            .name{
                width: 100%;
                color: white;
                background: #53BAE7;
                margin-top: 1em;
                text-align: center;
                font-weight: 500;
                font-size: 1.2em;
                padding: 5px;
                box-sizing: border-box;
            }
        }

        .story{
            margin-left: 1em;
            font-size: 1em;
            color: #5E5E5E;
        }

    }
    
`