import styled from 'styled-components';

export const Style = styled.div`

.terms{
    padding:5%;
}






`