import React from 'react';
import { Style } from './Style';
import Slide from 'react-reveal/Slide';

function WhomWeServe() {
    return (
        <Style>
            <section className="black">
                <Slide bottom>
                <h1 className="client-heading">
                    Who We Serve
                </h1>
                </Slide>

                <div className="client-service-icons">
                    <div className="client-service-icon">
                        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M18.3333 59.5833C23.375 59.5833 27.5 55.4583 27.5 50.4167C27.5 45.375 23.375 41.25 18.3333 41.25C13.2917 41.25 9.16667 45.375 9.16667 50.4167C9.16667 55.4583 13.2917 59.5833 18.3333 59.5833ZM23.5125 64.625C21.8167 64.35 20.1208 64.1667 18.3333 64.1667C13.7958 64.1667 9.4875 65.1292 5.59167 66.825C2.2 68.2917 0 71.5917 0 75.3042V82.5H20.625V75.1208C20.625 71.3167 21.6792 67.7417 23.5125 64.625ZM91.6667 59.5833C96.7083 59.5833 100.833 55.4583 100.833 50.4167C100.833 45.375 96.7083 41.25 91.6667 41.25C86.625 41.25 82.5 45.375 82.5 50.4167C82.5 55.4583 86.625 59.5833 91.6667 59.5833ZM110 75.3042C110 71.5917 107.8 68.2917 104.408 66.825C100.513 65.1292 96.2042 64.1667 91.6667 64.1667C89.8792 64.1667 88.1833 64.35 86.4875 64.625C88.3208 67.7417 89.375 71.3167 89.375 75.1208V82.5H110V75.3042ZM74.4333 62.5625C69.0708 60.1792 62.4708 58.4375 55 58.4375C47.5292 58.4375 40.9292 60.225 35.5667 62.5625C30.6167 64.7625 27.5 69.7125 27.5 75.1208V82.5H82.5V75.1208C82.5 69.7125 79.3833 64.7625 74.4333 62.5625ZM36.9875 73.3333C37.4 72.2792 37.5833 71.5458 41.1583 70.1708C45.6042 68.4292 50.2792 67.6042 55 67.6042C59.7208 67.6042 64.3958 68.4292 68.8417 70.1708C72.3708 71.5458 72.5542 72.2792 73.0125 73.3333H36.9875ZM55 36.6667C57.5208 36.6667 59.5833 38.7292 59.5833 41.25C59.5833 43.7708 57.5208 45.8333 55 45.8333C52.4792 45.8333 50.4167 43.7708 50.4167 41.25C50.4167 38.7292 52.4792 36.6667 55 36.6667ZM55 27.5C47.3917 27.5 41.25 33.6417 41.25 41.25C41.25 48.8583 47.3917 55 55 55C62.6083 55 68.75 48.8583 68.75 41.25C68.75 33.6417 62.6083 27.5 55 27.5Z"
                                fill="#FFE818" />
                        </svg>
                        <div className="client-service-label">
                            SMEs
                        </div>
                    </div>
                    <div className="client-service-icon">
                        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M77.9167 50.4167V13.75H32.0833V32.0833H13.75V96.25H50.4167V77.9167H59.5833V96.25H96.25V50.4167H77.9167ZM32.0833 87.0833H22.9167V77.9167H32.0833V87.0833ZM32.0833 68.75H22.9167V59.5833H32.0833V68.75ZM32.0833 50.4167H22.9167V41.25H32.0833V50.4167ZM50.4167 68.75H41.25V59.5833H50.4167V68.75ZM50.4167 50.4167H41.25V41.25H50.4167V50.4167ZM50.4167 32.0833H41.25V22.9167H50.4167V32.0833ZM68.75 68.75H59.5833V59.5833H68.75V68.75ZM68.75 50.4167H59.5833V41.25H68.75V50.4167ZM68.75 32.0833H59.5833V22.9167H68.75V32.0833ZM87.0833 87.0833H77.9167V77.9167H87.0833V87.0833ZM87.0833 68.75H77.9167V59.5833H87.0833V68.75Z"
                                fill="#FFE818" />
                        </svg>
                        <div className="client-service-label">
                            Corporations
                        </div>
                    </div>
                    <div className="client-service-icon">
                        <svg width="110" height="110" viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M77.9167 22.9165H13.75C8.70834 22.9165 4.58334 26.9957 4.58334 32.0832V73.3332H13.75C13.75 80.9415 19.8917 87.0832 27.5 87.0832C35.1083 87.0832 41.25 80.9415 41.25 73.3332H68.75C68.75 80.9415 74.8917 87.0832 82.5 87.0832C90.1083 87.0832 96.25 80.9415 96.25 73.3332H105.417V50.4165L77.9167 22.9165ZM68.75 32.0832H73.3333L87.0833 45.8332H68.75V32.0832ZM41.25 32.0832H59.5833V45.8332H41.25V32.0832ZM13.75 32.0832H32.0833V45.8332H13.75V32.0832ZM27.5 79.0623C24.3375 79.0623 21.7708 76.4957 21.7708 73.3332C21.7708 70.1707 24.3375 67.604 27.5 67.604C30.6625 67.604 33.2292 70.1707 33.2292 73.3332C33.2292 76.4957 30.6625 79.0623 27.5 79.0623ZM82.5 79.0623C79.3375 79.0623 76.7708 76.4957 76.7708 73.3332C76.7708 70.1707 79.3375 67.604 82.5 67.604C85.6625 67.604 88.2292 70.1707 88.2292 73.3332C88.2292 76.4957 85.6625 79.0623 82.5 79.0623ZM96.25 64.1665H92.675C90.1542 61.3707 86.5333 59.5832 82.5 59.5832C78.4667 59.5832 74.8458 61.3707 72.325 64.1665H37.675C35.1542 61.3707 31.5792 59.5832 27.5 59.5832C23.4208 59.5832 19.8458 61.3707 17.325 64.1665H13.75V54.9998H96.25V64.1665Z"
                                fill="#FFE818" />
                        </svg>
                        <div className="client-service-label">
                            Distributors
                        </div>
                    </div>
                </div>
                <Slide bottom>
                <div className="client-service-text">
                    <p>
                        "We rent out vehicles and equipment to companies for an upward of one month,
                        Backed by the guarantee of an existing contract, we provide lease financing to customers for the acquisition of operational assets,
                        Our asset lease management software helps companies manage their fleet, termed rentals, transactions, contracts, reconciliation and operations and maintenance logs and reminders"
                    </p>
                </div>
                </Slide>
                <div className="client-zagged">
                    <svg width="142" height="81" viewBox="0 0 142 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-5 4L19.1608 32L43.3217 4L67.4825 32L91.6434 4L115.822 32L140 4" stroke="#53BAE7"
                            stroke-width="4" stroke-miterlimit="10" />
                        <path d="M-5 49L19.1608 77L43.3217 49L67.4825 77L91.6434 49L115.822 77L140 49" stroke="#53BAE7"
                            stroke-width="4" stroke-miterlimit="10" />
                    </svg>
                </div>
            </section>
        </Style>
    )
}

export default WhomWeServe