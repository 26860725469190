import styled from 'styled-components';

export const Style = styled.div`


.welcome-elements {
    padding: 5%;
    min-height: 100vh;

    .contain {
        padding:5%;
        .welcome-sub-heading {
            font-size: 1.5em;
            color: #273883;
            text-align: center;
        }

        .welcome-heading {
            font-size: 3em;
            font-family: "Montserrat";
            font-weight: 600;
            text-align: center;
            margin-bottom: 0.3em;
        }
        .text{
            padding: 5%;
            line-height:2.2em;
        }
    }
}

.welcome-zagged{
    display: none;
}
hr {
    width: 40%;
    left: 30%;
    border: none;
    background: #53BAE7;
    height: 3px;
    margin-bottom: 6em;
}

/*Welcome decoration*/
@media screen and (min-width:600px) {
    .welcome-zagged {
        position: absolute;
        display: inline;
        left: 0;
        top: 100vh;
    }
}


.container{
    margin: auto;
    margin-bottom: 6em;

        .gallery-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: auto;
        }
}

`
export const StyleCard = styled.div`
    display: flex;

    .contain-cards {
        margin: 1em;
        max-width: 550px;
        

        @media screen and (max-width:1200px){
            width: 350px;
        } 

        img {
        max-width: 100%;
    }
    }
    
`