import React from 'react';
import {Style} from './Style';
import Slide from 'react-reveal/Slide';

function Services(){
    return(
        <Style>
            <section className="service-section">
                <div className="service-dots">
                    <svg width="76" height="192" viewBox="0 0 76 192" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.6348 45.9835C15.5593 50.0947 19.6416 52.6781 23.7529 51.7536C27.8641 50.8291 30.4475 46.7468 29.523 42.6355C28.5985 38.5242 24.5162 35.9409 20.4049 36.8654C16.2936 37.7899 13.7103 41.8722 14.6348 45.9835Z"
                            fill="#FFE818" />
                        <path
                            d="M29.3071 73.9456C30.2316 78.0569 34.3139 80.6403 38.4252 79.7158C42.5364 78.7912 45.1198 74.7089 44.1953 70.5977C43.2708 66.4864 39.1885 63.903 35.0772 64.8275C30.9659 65.7521 28.3826 69.8344 29.3071 73.9456Z"
                            fill="#FFE818" />
                        <path
                            d="M44.2135 101.855C45.138 105.967 49.2203 108.55 53.3315 107.625C57.4428 106.701 60.0262 102.619 59.1017 98.5073C58.1771 94.3961 54.0948 91.8127 49.9836 92.7372C45.8723 93.6617 43.2889 97.744 44.2135 101.855Z"
                            fill="#FFE818" />
                        <path
                            d="M58.876 129.82C59.8005 133.931 63.8828 136.514 67.9941 135.59C72.1053 134.665 74.6887 130.583 73.7642 126.472C72.8397 122.36 68.7574 119.777 64.6461 120.702C60.5348 121.626 57.9515 125.708 58.876 129.82Z"
                            fill="#FFE818" />
                        <path
                            d="M-15.1221 52.6749C-14.1976 56.7862 -10.1153 59.3695 -6.00403 58.445C-1.89277 57.5205 0.690604 53.4382 -0.233913 49.3269C-1.15843 45.2157 -5.24074 42.6323 -9.352 43.5568C-13.4633 44.4813 -16.0466 48.5636 -15.1221 52.6749Z"
                            fill="#FFE818" />
                        <path
                            d="M-0.449816 80.6373C0.474701 84.7485 4.55701 87.3319 8.66827 86.4074C12.7795 85.4829 15.3629 81.4006 14.4384 77.2893C13.5139 73.1781 9.43156 70.5947 5.3203 71.5192C1.20904 72.4437 -1.37433 76.526 -0.449816 80.6373Z"
                            fill="#FFE818" />
                        <path
                            d="M14.4566 108.547C15.3811 112.658 19.4634 115.241 23.5746 114.317C27.6859 113.392 30.2693 109.31 29.3448 105.199C28.4202 101.087 24.3379 98.5041 20.2267 99.4286C16.1154 100.353 13.532 104.435 14.4566 108.547Z"
                            fill="#FFE818" />
                        <path
                            d="M29.1191 136.511C30.0436 140.623 34.1259 143.206 38.2372 142.281C42.3484 141.357 44.9318 137.275 44.0073 133.163C43.0828 129.052 39.0005 126.469 34.8892 127.393C30.7779 128.318 28.1946 132.4 29.1191 136.511Z"
                            fill="#FFE818" />
                        <path
                            d="M-15.3003 115.238C-14.3758 119.35 -10.2935 121.933 -6.18226 121.008C-2.07099 120.084 0.51238 116.002 -0.412136 111.89C-1.33665 107.779 -5.41896 105.196 -9.53023 106.12C-13.6415 107.045 -16.2249 111.127 -15.3003 115.238Z"
                            fill="#FFE818" />
                        <path
                            d="M-0.637806 143.203C0.28671 147.314 4.36902 149.897 8.48028 148.973C12.5915 148.048 15.1749 143.966 14.2504 139.855C13.3259 135.743 9.24358 133.16 5.13232 134.085C1.02105 135.009 -1.56232 139.091 -0.637806 143.203Z"
                            fill="#FFE818" />
                    </svg>
                </div>
                <Slide bottom>
                <h1 className="service-heading">
                    What We Do
                </h1>
                </Slide>
                <div className="service-element">
                    <Slide bottom>
                    <div className="service-image">
                        <img className="img-responsive shadow" src="assets/pictures/pexels-torsten-dettlaff-70912.jpg" alt = "" />
                    </div>
                    </Slide>
                    <Slide bottom>
                    <div className="service-description">
                        <h3>Operational Lease</h3>
                        <p>
                            Through over 15,000 transactions and 2000 aggregated vendors, we have over the last 5 years rented
                            out equipment and vehicles worth over €2 billion to dozens of corporate clients across the
                            engineering, procurement and construction space.<br /> Our incident rate remains under 5%, we offer
                            bespoke insurance on assets and transactions, our turnaround time is remarkable regardless of what
                            you need to rent and we offer replacements at no additional costs.
                        </p>
                    </div>
                    </Slide>
                </div>
                <div className="service-element reverse">
                    <Slide bottom>
                    <div className="service-image">
                        <img className="img-responsive shadow" src="assets/pictures/pexels-quintin-gellar-2199293.jpg" alt = "" />
                    </div>
                    </Slide>
                    <Slide bottom>
                    <div className="service-description">
                        <h3>Financial Lease</h3>
                        <p>
                            With majority of assets on the continent, demand far outstrips supply. This is why we provide
                            financing to clients who require equipment or vehicles and are willing and able to repay over time,
                            the end objective being ownership of the acquired assets.
                            We will support your organisation through the process and leverage our extensive relationships with
                            financial partners and trusted equipment and vehicle manufactures to give you the best deals you can
                            find, across the region.
                        </p>
                    </div>
                    </Slide>
                </div>
                <div className="service-element">
                    <Slide bottom>
                    <div className="service-image">
                        <img className="img-responsive shadow" src="assets/pictures/pexels-pixabay-162639.jpg" alt = "" />
                    </div>
                    </Slide>
                    <Slide bottom>
                    <div className="service-description">
                        <h3>Lease Management</h3>
                        <p>
                            A critical part of our operations is vendor aggregation so we perfectly understand how tedious a
                            process it can be. Among the many lessons we’ve taken from managing thousands vendors across seventy
                            two asset classes and twelve cities in three African countries, the most important realisation is
                            that managing vendors can affect your organisation’s focus on it’s core objectives which is why
                            we’re offering to take that responsibility off you.
                            With our lease management tool, you can tell which vendor is doing what, when, where, oversee
                            operations, maintenance, record transactions, engage, manage contracts and dispute resolution on one
                            easy to use application. We are also happy to customise modules according to your needs.
                            Reach out to request a demo and the first month will be on the house after which you then have to
                            pay a graduated fee depending on the number of vendors and users.
                        </p>
                    </div>
                    </Slide>
                </div>
            </section>
        </Style>
    )
}

export default Services