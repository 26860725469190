import React from 'react';
import {Style} from './Style'
import HomeWelcome from '../../components/modules/homeWelcome/HomeWelcome';
import Services from '../../components/modules/services/Services';
import WhomWeServe from '../../components/modules/whomweserve/WhomWeServe';
import Testimonials from '../../components/modules/testimonials/Testimonials';


function Home () {
    return(
        <Style>
            <HomeWelcome />
            <Services />
            <WhomWeServe />
            <Testimonials />
        </Style>
    )
}

export default Home