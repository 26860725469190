import React from 'react';
import ReactDOM from 'react-dom';
import ReactRouter from './router/Routes';
import * as serviceWorker from './serviceWorker';

const renderNode = document.getElementById("content");
ReactDOM.render(
  <React.StrictMode>
    <ReactRouter />
  </React.StrictMode>,
  renderNode
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
