import styled from 'styled-components';

export const Style = styled.div`

.privacy-policy{
    padding:5%;
}






`