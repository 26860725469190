import React, { Component } from 'react';
import { Style } from './Style';

export default class PrivacyPolicy extends Component {
    render() {
        return (
            // <!-- Breadcumb -->
            <Style>
                <div>
                    <section className="privacy-policy  privacy">
                        <div>
                            <h1 >PRIVACY POLICY</h1>
                            <hr />
                            <p>        Your privacy is important to us. This <a href="/Privacy"> privacy policy</a> provides information about the personal
                                        information that Eazyhire Limited collects, and the ways in which Eazyhire Limited uses that
                                        personal information. By using this website, you agree to the collection and use of information
                                        within the terms of this <a href="/Privacy">privacy policy</a> . If you do not agree with this privacy policy, then do not use
                                        this site or give Eazyhire Limited any of your information. This privacy policy is governed by our <a href="/Terms"> Terms & Conditions</a></p>

                            <h2  className="welcome-sub-heading">Personal Information Collection</h2>
                            <p>Eazyhire Limited, on its own or through third parties or agents, may collect and use personal
                            information, which may include, but is not limited to:
                            your name, email, address, and telephone number information that you provide for the purpose of
                            registering with the website and/or the Eazyhire Limited blog or social media accounts, information
                            relevant to transactions, including credit/debit card and shipping information for order fulfillment,
                            conducted over this website, information that you provide for the purpose of subscribing to
                            communications, purchase interest and purchase history information. Eazyhire Limited also
                                        collects content and information you provide for the purpose of interacting with the Eazyhire Limited social media accounts or blog(s).</p>
                            <p> Eazyhire Limited further collects information that your internet browser sends whenever you visit or use this site. This information, sometimes called <strong>"Log Data"</strong> or <strong>"Log Files"</strong>, may include your internet protocol address, your internet service provider, browser information including your referring and exit pages, your operating system, which pages you visit on Eazyhire Limited’s website, the time and date of your visit and use of Eazyhire Limited’s pages, and additional statistical information.</p>
                            <p>In addition, Eazyhire Limited collects on its own, and employs third parties and third-party technology to assist with, transactions, marketing, and analytics. Eazyhire Limited and these third parties collect information that is shared with Eazyhire Limited. regarding your interests and purchase behavior, including what you have liked and clicked, and whether you have completed or abandoned a transaction.</p>
                            <p>More specifically, Eazyhire Limited uses Google AdWords as a remarketing service to advertise on third-party websites (including Google) to previous visitors of this site. This means that we may advertise to previous visitors to our website who may or may not have completed a task or transaction on our site. This could be in the form of an advertisement on the Google search results page, or a site across the internet in the Google Display Network.</p>

                            <h2 className="welcome-sub-heading">Cookies, Beacons, and Opting out</h2>
                            <p>Eazyhire Limited uses cookies and similar technology (e.g., web beacons and single-pixel GIF
                            files) to record Log Data and Log Files. Cookies are small files that a site or its service provider
                            transfers to your computer’s hard drive through your internet browser (if you allow) that enables the
                            sites’ or service providers’ systems to recognize your browser and capture and remember certain
                                        information. This helps us to improve your access to our site and identify repeat visitors.</p>
                            <p>We use session-based cookies and persistent cookies. Session-based cookies will last only as
                            long as your web browser is open, and are deleted when you close your browser. A persistent
                                        cookie will last until it expires or until you delete it. At times, Eazyhire Limited administers the use of cookies (called<strong> first-party cookies</strong>) and tracking technologies, and at times we rely upon third parties (e.g., Google Analytics, AdRoll) to do so (called<strong> third-party cookies</strong>).</p>
                            <p>We use these cookies to help us do a number of things to enhance your experience, including to:<br />        Remember and process the items in your shopping cart, understand and save your preferences for future visits, ensure you do not need to login multiple times through user identification tracking, compile statistics and reports on our website and web traffic activity, track and target your interests to enhance your experience on our website, including searches and web traffic adjacent to activity on this website, serve advertisements based on a prior visit to this website, allow for integration with social media, provide other services related to this website.</p>
                            <p>You may opt out of third-party cookies from Google Analytics and AdRoll at their respective websites. You may also visit the Network Advertising Initiative (NAI) for additional cookie opt-out options. Note that if you wish to opt out, you will need to do so on each browser and device that you use, and will need to opt out each time you delete your cookies or upgrade your browser.</p>
                            <p>If at any time you wish to opt out of receiving marketing materials or emails from Eazyhire Limited, including our newsletter, you may do so by writing us on <a href="info@eazyhire.com.ng">info@eazyhire.com.ng</a></p>

                            <h2 className="welcome-sub-heading">Children</h2>
                            <p>Eazyhire Limited’s websites are not intended for use by children under the age of 18.<br />We do not knowingly collect or store any personal information from children under the age of 18.
                                        We encourage parents and guardians to spend time with their children online and to be fully
                                        familiar with the online services used by their children. If a parent or guardian becomes aware that
                                        his or her child under 18 has provided us with information without his or her consent, he or she should contact us at <a href="support@eazyhire.com.ng">support@eazyhire.com.ng.</a> As required by law, we will delete such information from our files within a reasonable time.</p>

                            <h2 className="welcome-sub-heading">Using Personal Information</h2>
                            <p>Eazyhire Limited may use your personal information to:</p>
                            <ol style={{ listStyleType: "disc" }}>
                                <li>Administer this website, personalize the website for you, enable your access to use the website and its services,</li>
                                <li>conduct transactions and send you products that you purchase, to process payments and fulfill purchase requests,</li>
                                <li>send you statements and invoices, send you promotional and marketing communications, including our newsletter, direct mail, and direct email, and process promotions, surveys, contests, and sweepstakes, and related prizes or incentives, contact you.</li>
                            </ol>
                            <p>Where Eazyhire Limited discloses your personal information to its agents or sub-contractors for these purposes, the agent or sub-contractor in question will use that personal information in accordance with the terms of this privacy policy statement.</p>
                            <p>In addition to the disclosures reasonably necessary for the purposes identified within this privacy policy statement, Eazyhire Limited may disclose your personal information to the extent that it is required to do so by law, in connection with any legal proceedings or prospective legal proceedings, and in order to establish, exercise, or defend its legal rights.</p>
                            <h2 className="welcome-sub-heading">Orders</h2>
                            <p>For rentals, leases and all other transactions, we may collect payment information, including credit/debit card information, account information, merchandise/service credit, and billing/shipping information. When you rent or purchase through our website or over the phone, we collect information related to each transaction, including what products/services you purchased or did not purchase, the price paid, your method of payment, and any promotional information related to the transaction. Additionally, we collect order information for the purpose of better targeting our advertising and promotions for you.</p>

                            <h2 className="welcome-sub-heading">Surveys and Promotions</h2>
                            <p>At times Eazyhire Limited may request information from you for surveys or promotions (including contests and sweepstakes). Your participation in these surveys and promotions is voluntary, and your choice to provide information is voluntary and at your discretion. In administering surveys or promotions, Eazyhire Limited may request contact information and demographic information from you. Contact information and demographic information will be used in accordance with this <a href="/PrivacyPolicy">privacy policy</a>, this website’s <a href="/TermsAndCondition"> Terms & Conditions</a>, and any additional rules and regulations that are part of the survey or promotion.</p>

                            <h2 className="welcome-sub-heading">Securing Your Data</h2>
                            <p>Eazyhire Limited will take reasonable technical and organizational precautions to prevent the loss, misuse, disclosure, or alteration of your personal information. Eazyhire Limited will store all the personal information you provide on its secure servers. While Eazyhire Limited will employ  reasonable security practices to protect your information, no method of storage or electronic transmission is 100% secure, and as such, Eazyhire Limited cannot guarantee absolute security of any information you have provided.</p>

                            <h2 className="welcome-sub-heading">Usernames & Passwords</h2>
                            <p>It is important that you protect and maintain the security of any account and that you notify us immediately of any unauthorized use of your Eazyhire account. Do not disclose your password to anyone else.</p>

                            <h2 className="welcome-sub-heading">Updating This Privacy Policy</h2>
                            <p>
                                <ul style={{ listStyleType: "circle" }}>
                                    <li>Eazyhire Limited may update this privacy policy by posting a new version on this website.</li>
                                    <li>Eazyhire Limited reserves the right to modify or update this privacy policy at any time, and you
                                    should check this page regularly to ensure you are familiar with any changes. The effective date of
                                    the latest of any modification or update to the privacy policy is indicated at the top or bottom of this
                                    privacy policy following "Last Updated:".
                                            Other websites</li>
                                    <li>This website contains links to other websites that are not owned or controlled by Eazyhire Limited</li>
                                    <li>Eazyhire Limited is not responsible for the privacy policies or practices of any other such websites
                                    and encourages you to review the privacy policies of those websites. This includes, but is not
                                    limited to, social media sites (e.g., Instagram, Pinterest, Facebook, Twitter, YouTube, etc.), which
                                            are not affiliated with Eazyhire Limited</li>
                                </ul>
                            </p>

                            <h2 className="welcome-sub-heading">Contact</h2>
                            <p>If you have any questions about this privacy policy statement, please contact Eazyhire Limited at:
                                            Email: <a href="support@eazyhire.com.ng">support@eazyhire.com.ng</a>
                            </p>

                        </div>
                    </section>
                </div>
            </Style>
        )
    }
}