import React, { Component } from 'react';
import { Style } from './Style';


export default class TermsAndCondition extends Component {
    render() {
        return (
            <Style>
                <section className="terms">
                    <div>

                        <h1>TERMS AND CONDITIONS</h1>
                        <hr />
                        <p><strong>Welcome to the Eazyhire Limited ("Eazyhire," "Company," "We," "Us," or "Our") website. These Terms are a contract between You ("You" or "Your") and Eazyhire Limited including its successors, subsidiaries, and affiliates (collectively "Eazyhire," "Company," "We," "Us," or "Our"), with regard to the access and use of Our website and its component pages ("Site"), content, and/or online services made available through the Site or offline. Any references to Eazyhire shall include, where appropriate, its employees, officers, directors, investors, agents, vendors, consultants, assignees, affiliates, partners, contractors, attorneys, accountants, advertisers, and any and all other individuals and organizations providing services on behalf of Eazyhire. Please read these Terms fully and carefully before accessing and using Our Site.</strong></p>

                        <br />
                        <ul style={{ listStyleType: "decimal" }} >
                            <li>
                                <h2 className="welcome-sub-heading">Terms of Use</h2>
                            </li>
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>
                                    <p><strong>Acceptance</strong>. By accessing, signing up, logging in and using Our Site, You <strong>accept</strong> and <strong>agree</strong> to be legally bound by these Terms and all prospective amendments to these Terms, including Our <a href="/Privacy"> Privacy Policy</a>. This agreement is made by <strong>You</strong> just as if it had been made in writing. If You do not agree to these <strong>Terms</strong>, do not use this Site or any of our online features, products, or services.</p>
                                </li>

                                <li><p><strong>Amendment</strong>.We may amend these Terms from time to time in Our sole discretion. All amendments to these Terms will be effective immediately upon posting. Your continued access to Our Site constitutes an agreement to be bound to the Terms then effective and as amended. You also agree to accept notice of posting of any amendments or new terms through Us posting such amendments or new terms on the Site. Please review the Terms periodically so you are aware of any amendments.</p></li>

                                <li><p><strong>Additional Terms</strong>. Although you are always bound by the Terms, in using additional features, products, or services you may be bound by and subject to additional terms, including but not limited to Frequently Asked Questions ("<a href="/Faq">FAQs</a>"), guidelines, and rules applicable to certain features, products, or services. These additional terms may be posted and modified periodically. Any and all additional terms will not change or replace these Terms regarding use of the Site, unless expressly stated. Any and all additional terms are incorporated within these Terms by reference. To the extent You participate in Our contest, giveaways, or marketing activity ("<strong>Promotion</strong>"), these Terms apply with any additional promotion rules, regulations, contracts, terms or agreements.</p></li>

                                <li><p><strong>Capacity</strong>. This is a general audience Site and service for adults. We will assume (and by using this Site You warrant that) You have legal capacity to enter into the agreement set out in these Terms, including but not limited to, that You are at least 18 years of age, of sufficient age and mental capacity, and are otherwise entitled to be legally bound in contract.</p></li>
                            </ol>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Eazyhire Account</h2>
                            </li>
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li><p><b>Login</b>. Our Site contains an account login feature to allow You to search, save, and request for Our products and services. We reserve the right to determine, in Our sole discretion, the products and services offered, the number of requests permitted, the length of time each item or request remains on the Site, the products and services that are offered in Our quote system, whether We will respond to any quote, and any other features, products, or services offered as part of this or any other feature on the Site. Quotes and Prices provided are subject to change at any time and without prior notice.</p></li>

                                <li><p><b>Eligibility</b>; Account Information. In order to create an account, you must be at least <strong>eighteen (18)</strong> years of age and complete the registration process by providing us with complete, current, and accurate information as requested in our online registration form. By creating an account, You agree to provide true, accurate, and current data in connection with that account. Any usernames and passwords used for this Site are for individual use only. You are solely responsible for maintaining the security and confidentiality of the password You use to access Your account. You agree to notify Eazyhire immediately of any unauthorized use of Your username, password, or account, or any other breach of security. We are not liable for any loss that You may incur as a result of someone using Your username, password, or account, either with or without Your knowledge. This site is available in select countries, and these terms apply only to persons within these select countries.</p>
                                    <p>This site is also not available to any persons suspended or removed from the site by Eazyhire. In using this site, you represent that you are a resident of the select countries in our coverage who has not been suspended or removed from the site. Persons choosing to access this site do so at their own discretion and initiative, and are responsible for compliance with these terms, all local laws and rules, including but not limited to, such laws and rules regarding the internet, date, email, electronic messages, privacy, and security.</p></li>

                                <li><p><b>Social Features</b>. You understand and agree that the Site may include a blog and additional social network/engagement functions and Your activities (e.g., article/video viewing, content viewing, comment posting, interactions with individuals and Eazyhire on the Site, etc.) may be shared with others both on and off the Site. If you do not want Your activities to be shared, Your only option is to not participate in that portion of the Site.</p></li>

                                <li><p><b>Length</b>. Your account will continue until You request to cancel Your account or Eazyhire, in its sole discretion, cancels Your account.</p></li>

                                <li><p><b>Use</b>. The Site and its contents are for Your personal and non-commercial use only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products, services, media, or features obtained from the Site without Our prior written consent. Your account may not be assigned or transferred to any other person or entity, nor may You provide any other person or entity with access to Your account, either directly or indirectly.</p></li>

                                <li><p><b>Lease</b>: Items or services requested on the Site, over the phone or through any of our social media or digital or offline channels will be guided by our usage policy. You may not use a requested items for any purpose other than the default or vendor prescribed one. If this condition is violated, you will be held fully liable for any damage or loss that may be incurred and/or any litigation that might arise out of such misuse or negligence, including but not limited to attorney fees.</p></li>

                                <li><p><b>Payment</b>: Where full upfront payment on a request is not made, Eazyhire fully reserves the right to refuse to deliver items or services requested or to cancel a request and impose a surcharge.</p></li>

                                <li><p><b>Delivery</b>: Eazyhire does not deliver requested items or services except where expressly stated and in such cases, a charge is included.</p></li>

                                <li><p><b>Insurance</b>: While insurance is provided on items and transactions, we reserve the right to decide what insurance covers depending on a variety of factors including but not limited to request duration, nature of item or service, pricing, user and vendor ratings or reviews, location of use etc. We will not be held liable for the choices we make on whether or not to entertain claims or provide warranties on any items, delivery, services or transactions. We reserve the right to charge a premium per transaction, the value of which shall be solely at our discretion.</p></li>

                                <li><p><b>Unavailability</b>. Your access to the Site and Your account may be unavailable or interrupted from time to time for a variety of reasons, many of which We cannot control. We shall not be held responsible for any interruptions of Your access to the Site or your account, or any of the consequences of such interruptions.</p></li>

                                <li><p><b>Right to Refuse</b>. Eazyhire reserves the right to refuse or revoke service or access to the Site to You or any user at any time.</p></li>
                            </ol>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Termination; Modification</h2>
                            </li>
                            <p>Without any further notice and at any time, You understand and agree that Eazyhire may terminate, cancel, deactivate, and/or suspend Your access to the Site and to Your account, including any and all of Your items or requests. Eazyhire reserves the right to discontinue, modify, alter, or change any policy, feature, product, or service of the Site, including Your account. You understand, acknowledge, and agree that Your sole right regarding the termination or modification of Your account or the Site, is to cancel Your account and to stop using the Site. To cancel your account<a href="  mailto:support@eazyhire.com.ng?subject=Email%20Subject&body=Email%20Body%20Text" target="blank"> email</a>
                            </p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading"><a href="/Privacy">Privacy</a></h2>
                            </li>
                            <p>Your privacy is important to Us. By using this site, You agree to the collection and use of your information within the terms of our Privacy Policy. Please review our Privacy Policy regularly for more information.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Monitoring</h2>
                            </li>
                            <p>Eazyhire may, but has no obligation to, monitor the use by You and other users of the Site. During monitoring, any information relating to any user or their respective activities on the Site may be examined, recorded, copied, and used for authorized purposes in accordance with these Terms and Our Privacy Policy. Furthermore, Eazyhire reserves the right at all times to disclose any information posted on any portion of the Site as necessary to satisfy any law, regulation, or governmental request, or to refuse to post, or to remove, any information or materials, in whole or in part, that in Eazyhire’s sole and absolute discretion finds objectionable or in violation of these Terms.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Conduct and Acceptable Use</h2>
                            </li>
                            <p>The following rules are a condition of Your use of and access to the Site, including but not limited to listings, our blog and social media platforms. You are responsible for the content of Your communications and representations on the Site, and those communications and representations made in Your account on the Site.
                  We may require, at any time, proof that You are following these rules.</p>
                            <p>We reserve the right to take, or refrain from taking, any and all steps available to Us, including suspending or terminating Your access to the Site or seeking other legal or equitable remedies, once We become aware of any violation of these Terms.</p>

                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>No Interference. You may not interfere with any other user from using or enjoying the Site.</li>
                                <li>No Bullying. You may not use the Site to threaten, abuse, harass, or invade the privacy of any person.</li>
                                <li>Content Posted By You Must Be Yours. You may not upload, post, transmit, or otherwise distribute or facilitate the distribution of content that is fraudulent or infringes the rights of any third party, including but not limited to, patent, trademark, copyright, right of publicity, or other proprietary rights.</li>
                                <li>Your Materials Must Be Lawful And Appropriate.You may not upload, post, or otherwise distribute or facilitate the distribution of any unlawful, threatening, abusive, libelous, defamatory, racist, religious, tribalistic, obscene, pornographic, profane, indecent, or otherwise inappropriate information of any kind, including but not limited to, any images or other material of a sexual nature.</li>
                                <li>Do Not Damage Our Site Or Servers. You may not upload, post, or otherwise distribute or facilitate the distribution of a software viruses, Trojan horses, worms, time bombs, cancelbots or any other computer code or files that are designed to disrupt, damage, or limit the functioning of any software, hardware, or portion of the Site, any other online services, or to obtain unauthorized access to the Site, content, or any data or other information of any third party.</li>
                                <li>	No Unauthorized Access.You may not attempt to gain unauthorized access to other computer systems or networks connected to the Site or use the Site, its content, or any information contained therein for any unlawful purpose. Eazyhire, in its sole and absolute discretion, shall determine whether any content You transmit or receive or Your use of the Site and its content violates this provision.</li>  <li>	No Collection Of Personal Information From Other Users And No Commercial Use. You may not collect information about other users of the online services in violation of our Privacy Policy or use any such information for the purpose of transmitting or facilitating transmission of unauthorized or unsolicited advertising, junk, or bulk email, chain letters, or any other form of unauthorized solicitation.</li>
                                <li>Be Yourself. You may not impersonate, falsely state, or otherwise misrepresent Your professional or other affiliation to Eazyhire or with any other person or entity.</li>
                                <li>No Criminal Or Unlawful Conduct. You may not use the Site or its content or any information contained therein for any unlawful purpose, and You may not encourage conduct that would constitute a criminal offense or give rise to civil liability. You may not use any services in connection with any site or other use that contains or is associated with information or content prohibited by this section.</li>
                                <li>Follow These Terms And Conduct Rules. You may not take any action on the Site that violates any applicable law or these Terms.</li>
                            </ol>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">User Submissions and Content</h2>
                            </li>
                            <p>The site may provide certain features that permit You and other users to submit content, which may include text, pictures, videos, messages, suggestions, materials, works of authorship, ideas, or concepts (<strong>"User Content"</strong>). At times, this content may be shared by You, other users, or Eazyhire.</p>
                            <p>Any User Content shall become, and remain, the property of Eazyhire. You agree that any User Content, in whole or in part, may be used by Eazyhire for any purpose including modification, reproduction, transmission, publication, advertising, press, publicity, broadcast, and posting in any media, including but not limited to in connection with television and/or radio broadcasts. You additionally agree that Eazyhire is free to use any ideas, concepts, techniques, or know-how contained in any User Content you send to or post on or via the Site for any purpose whatsoever including but not limited to developing, manufacturing, marketing, and selling products and services based upon such information without any obligation to compensate you or anyone else for them.</p>
                            <p>By submitting such User Content, you are assigning and transferring any and all right, title and interest in User Content to Eazyhire, including any moral rights. You represent and warrant that User Content does not infringe any third-party intellectual property rights. You agree not to transmit any unlawful, threatening, harassing, libelous, defamatory, obscene, pornographic, or profane material, any material submitted without permission under another person’s name, or other material that could constitute or encourage conduct that could be considered a criminal offense or violate any law. You shall not, without the express written approval of Eazyhire submit any materials which contains advertising or any solicitation with respect to products or services beyond the permitted scope. Eazyhire shall have the right, in its sole discretion, to edit or remove any material portion of User Content, which in its sole discretion, it finds to be in violation of the provisions of this agreement or otherwise objectionable.</p>
                            <p>Do not post your personally identifiable contact information in any part of the site accessible to users, including but not limited to your telephone number, address, home, business or email address. Any posting of such personal information with or without authorisation is prohibited and expressly at your own risk.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Ownership; Proprietary Rights</h2>
                            </li>
                            <p>All Site content and materials including, but not limited to, articles, other text, logos, photographs, illustrations, graphics, product names, designs, logos, video material, and audio clips (collectively, <strong>"the Intellectual Property"</strong>) are protected by copyright, trademark, and other laws of the Nigeria, as well as international conventions and the laws of other countries. The Intellectual Property is owned or controlled by Eazyhire or the party credited as the provider or owner of the Intellectual Property. The compilation (meaning the collection, arrangement, and assembly) of all content on the Site is the exclusive property of Eazyhire and protected by Nigeria and international copyright laws. Except as expressly authorized by Eazyhire, You agree not to sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt, create derivative works from, or otherwise make unauthorized use of all Site content and materials.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Third-party Sites, Products, & Services; Links</h2>
                            </li>
                            <p>The site may contain links to third party websites (<strong>"Third-party sites"</strong>). Eazyhire is not responsible for third-party sites and their content. The third-party sites are not controlled by Eazyhire. Accordingly, Eazyhire makes no warranties or conditions regarding such third-party sites, has no responsibility for the contents of such third-party sites, and will not be liable for any loss or damage caused by your use of or reliance on such third-party sites. Your use of third-party sites is at your own risk. The inclusion on the site or other service of a link to a third-party site does not imply an endorsement by Eazyhire. When you access any of these third-party sites, please understand that if you visit third-party sites then your rights and obligations while accessing and using those sites will be governed by the agreements and policies relating to the use of those sites.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Disclaimer; No Warranties; Limitation on Liability and Damages</h2>
                            </li>

                            <p>Subject to the final paragraph of this section, Eazyhire and/or its respective vendors, licensors, affiliates, and suppliers make no representations about the suitability, reliability, availability, timeliness, lack of viruses, or other harmful components and accuracy of the information, software, products, services, and related graphics contained within the site, content, or user content for any purpose. All such information, software, products, services and related graphics are provided without warranty of any kind. To the extent allowable by law, Eazyhire and/or its respective vendors, licensors, affiliates, and suppliers hereby disclaim all warranties and conditions with regard to this information, software, products, services, and related graphics, including all implied warranties and conditions of merchantability, fitness for a particular purpose, workmanlike effort, title, and non-infringement.</p>
                            <p>You also specifically agree that Eazyhire is not responsible for any content sent using and/or included in the site by any third party. You further agree that Eazyhire and/or its respective vendors, licensors, affiliates, and suppliers may make improvements and/or changes to the site and/or may discontinue any part of the site at any time.</p>
                            <p>Also subject to the final paragraph of this section and to the extent allowable by law, in no event shall Eazyhire be liable for any direct, indirect, punitive, incidental, special, consequential damages, or any damages whatsoever if this limitation of liability or the exclusion of warranty set forth above is held inapplicable or unforceable for any reason, then Eazyhire’s maximum liability for any type of damages shall be limited to 50,000 Naira. If you are dissatisfied with any portion of the site, or with any of these terms, your sole and exclusive remedy is to discontinue using the site and terminate your account.</p>
                            <p>Notwithstanding the above, nothing in this section shall be understood to limit the liability of an Eazyhire vendor or user for poor service, failure to deliver on request after payment, delay or failure in returning rented item, non payment, personal injury or death caused by the vendor or user's own negligence or intentional or unlawful misconduct or for any damages caused by their failure to properly manage or make use of requested items. </p>

                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Indemnification; Hold Harmless</h2>
                            </li>
                            <p>You agree to <strong>indemnify</strong> and hold Eazyhire its employees, officers, directors, investors, agents, consultants, assignees, affiliates, partners, contractors, attorneys, accountants, advertisers, and any and all other individuals and organizations providing services on behalf of Eazyhire harmless from all claims, liabilities, losses, damages, and expenses (including attorneys’ fees and expenses) arising out of or relating to Your breach or alleged breach of any terms or conditions contained in this Agreement. </p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Dispute</h2>
                            </li>
                            <p>These Terms, including Our Privacy Policy, are governed by the laws of Nigeria, without respect to its conflict of laws principles. Jurisdiction for any claims, lawsuits, or other actions (collectively, <strong>"Claims"</strong>) arising under this agreement shall lie exclusively with the courts within Lagos Nigeria. If any provision of this <a href="/Privacy"> Privacy Policy</a> and the <a href="/Terms"> Terms & Conditions</a> are found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these terms and conditions, which shall remain in full force and effect. No waiver of any term of this <a href="/Privacy">Privacy Policy</a> and the <a href="/Terms">Terms & Conditions</a> shall be deemed a further or continuing waiver of such term or any other term. You agree that neither you, nor your representatives, shall have the right or authority to bring any <strong>Claims</strong> on a class action basis or on bases involving <strong>Claims</strong> brought in a purported representative capacity on behalf of the general public, other subscribers, or other persons similarly situated.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Assignment</h2>
                            </li>
                            <p>Eazyhire may assign its rights and obligations under these Terms, and any assignment will inure to the benefit of Our successors, assigns, and licensees.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Severability</h2>
                            </li>
                            <p>If any provision of these <a href="/Terms">Terms</a>, including the <a href="/Privacy">Privacy Policy</a>, is found invalid, illegal, or unenforceable by any court of competent jurisdiction, the remaining provisions shall remain in full force and effect, as shall this agreement, as if such invalid, illegal, or unenforceable provisions had never been a part of these Terms.</p>
                            <br />

                            <li>
                                <h2  className="welcome-sub-heading">Survivability</h2>
                            </li>
                            <p>Sections 4,7,8,11, 12,13, 14,16,18,19, and 20 shall survive any termination of this Agreement.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Headings</h2>
                            </li>
                            <p>All headings used in these Terms are for reference purposes only and shall not be used to interpret, analyze, or construe these Terms or the terms, representations, covenants, agreements, certifications, declarations, affirmances, or statements contained herein.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Entire Agreement</h2>
                            </li>
                            <p>These Terms, including the Privacy Policy, represent the entire agreement between Eazyhire and You regarding the subject matter of these Terms. These Terms cancel and supersede all prior understandings between Eazyhire and You regarding the rights herein. No provision of these Terms shall be modified or amended except as expressly stated within the Terms.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">No waiver</h2>
                            </li>
                            <p>No waiver of any of these Terms by Eazyhire is binding unless authorized in writing by an executive officer of Eazyhire. If Eazyhire waives a breach of any provision of the Terms, any such waiver will not be construed as a continuing waiver of other breaches of the same nature or other provisions of the Terms and will in no manner affect the right of Eazyhire to enforce the same at a later time.</p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Children’s Online Privacy Protection Act ("coppa") Notification</h2>
                            </li>
                            <p>Eazyhire is not designed or intended for use by children under the age of 18.  Pursuant to 47 U.S.C. Section 230(d) as amended, Eazyhire hereby notifies You that parental control protections (such as computer hardware, software, or filtering services) are commercially available that may assist You in limiting access to material that is harmful to minors. Information identifying current providers of such protections is available at the Electronic Frontier Foundation website, <a href="http:// www.eff.org">www.eff.org</a></p>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Digital Millennium Copyright Act</h2>
                            </li>
                            <p>Pursuant to Title II of the Digital Millennium Copyright Act, all claims of copyright infringement for material that is believed to be residing on the Site, should be promptly sent in the form of written communication to Eazyhire, Limited, support@eazyhire.com.ng. All claims must include the following information:</p>
                            <ol style={{ listStyleType: "lower-alpha" }}>
                                <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;</li>
                                <li>Identification of the copyrighted work claimed to have been infringed, or if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site;</li>
                                <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material;</li>
                                <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and if available, an electronic mail address at which the complaining party may be contacted;</li>
                                <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</li>
                                <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the Complaining Party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
                            </ol>
                            <br />

                            <li>
                                <h2 className="welcome-sub-heading">Contact</h2>
                            </li>
                            <p>If you have any questions about these <a href="/Terms">Terms</a>, including Our <a href="/Privacy">Privacy Policy</a>, please contact Us at: <a href="support@eazyhire.com.ng">support@eazyhire.com.ng</a>
                            </p>

                        </ul>

                    </div>
                </section>
            </Style>
        )
    }
}