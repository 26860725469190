import React from 'react';
import {StyleCard} from './Style';

function Card ({props}) {
    return(
        <StyleCard>
            <div className = 'contain-cards'>
                <div className = 'image-name-container'>
                    <img src = {props.img} alt = '.' />
                    <div className ='name'>{props.name}</div>
                </div>
                <div className = 'story'>
                    <div>{props.story}</div>
                </div>
            </div>
        </StyleCard>
    )
}

export default Card