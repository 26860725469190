import React from 'react';
import ClientLogos from '../../components/modules/clientlogos/ClientLogos';

function Portfolio () {
    return(
        <section className="testimonials-section">
            <h1>We Are Trusted by Them</h1>
                <ClientLogos></ClientLogos>
        </section>
    )
}

export default Portfolio