import styled from 'styled-components'

export const Style = styled.div`


body {
    font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 400;
    font-style: normal;
}

section {
    min-height: 100vh;
    font-size: 1em;
}

nav {
    display: flex;
}

::selection {
    color: white;
    background-color: black;
}

/*Top Navs*/
.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.logo {
    font-size: 2.5em;
    font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 700;
    padding: 20px 20px;
    width: 100px;
}

.logo>a {
    text-decoration: none;
    color: black;
}

.navigation {
    float: right;
    min-width: 200px;
    padding: 20px 20px;
}

.navigation>a {
    font-size: 1.25em;
    color: #273883;
    padding: 10px 10px;
    text-decoration: none;
}

.navigation>a:hover,
a:focus {
    color: #FFE818;
    background-color: black;
}

.nav-icon,
.nav-close {
    display: none;
}

.navigation>a:hover.nav-icon,
a:focus.nav-icon {
    background: none;
}

@media screen and (max-width:600px) {
    .navigation>a:not(:first-child) {
        display: none;
    }

    .navigation {
        min-width: auto;
        padding: 10px 50px;
    }

    .nav-icon {
        display: contents;
    }

    .nav-responsive {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fffffff5;
        height: 100%;
        width: 100%;
        overflow-x: hidden;
        transition: 0.5s linear all;
    }

    .nav-responsive>.menu>.navigation>a:not(:first-child) {
        display: flex;
    }

    .nav-responsive>.menu>.navigation>.nav-close {
        display: contents;
    }

    .nav-responsive>.menu>.navigation>a:first-child {
        display: none;
    }

    .nav-responsive>.mobile-menu>div:first-child {
        display: none;
    }

    .nav-responsive>.mobile-menu>.navigation {
        flex-direction: column;
        min-height: 50vh;
        color: white;
    }

    .mobile-menu>.navigation>a {
        font-size: 1.75em;
        color: black;
    }

    .mobile-menu>.navigation>a:focus,
    a:hover {
        font-size: 1.75em;
        color: black;
        background: none;
    }
}

/*Header Section*/
.welcome {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: right;
}

.welcome-image {
    padding: 9%;
    transition: 0.5s;
}
.welcome-image > svg{
transition: 1s;
}
@media screen and (max-width:320px){
    .welcome-image > svg{
        width:50%;
        }
}
// .swing-top-bck {
// 	-webkit-animation: swing-top-bck 1s cubic-bezier(0.445, 0.050, 0.550, 0.950)  reverse forwards;
// 	        animation: swing-top-bck 1s cubic-bezier(0.445, 0.050, 0.550, 0.950)  reverse forwards;
// }
// @-webkit-keyframes swing-top-bck {
//   0% {
//     -webkit-transform: rotateX(0);
//     opacity:1;
//             transform: rotateX(0);
//     -webkit-transform-origin: top;
//             transform-origin: top;
//   }
//   100% {
//     -webkit-transform: rotateX(-180deg);
//             transform: rotateX(-180deg);
//             opacity:0;
//     -webkit-transform-origin: top;
//             transform-origin: top;
//   }
// }
// @keyframes swing-top-bck {
//   0% {
//     -webkit-transform: rotateX(0);
//             transform: rotateX(0);
//             opacity:1;
//     -webkit-transform-origin: top;
//             transform-origin: top;
//   }
//   100% {
//     -webkit-transform: rotateX(-180deg);
//             transform: rotateX(-180deg);
//             opacity:0;
//     -webkit-transform-origin: top;
//             transform-origin: top;
//   }
// }

.welcome-image > svg:hover{
    transform: translateX(50px);
}

.welcome-elements {
    padding: 5%;
}

.welcome-heading {
    padding-top: 15%;
    font-size: 3em;
    font-family: "Montserrat";
    font-weight: 600;
    margin-bottom: 0;
}

.welcome-sub-heading {
    font-size: 1.5em;
    color: #273883;
    margin-top: 0;
}

.cta-header {
    margin-top: 100px;
    min-width: 100%;
}

.welcome-zagged {
    display: none;
}

/*Welcome decoration*/
@media screen and (min-width:600px) {
    .welcome-zagged {
        position: absolute;
        display: inline;
        left: 0;
        top: 110vh;
    }

    .welcome:before {
        content: "";
        position: absolute;
        bottom: 0;
        width: 40%;
        left: 30%;
        /*this centers it based on the above width*/
        border-bottom: #53BAE7 3px solid;
    }
}

/*Subheading Section*/
.sub-heading {
    display: flex;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.sub-heading>.sub-heading-contents>h2 {
    font-size: 2.5em;
    font-weight: 500;
    color: #273883;

}

.sub-heading-contents {
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/*sub heading decorations*/
.sub-heading-dots {
    position: absolute;
    top: 180vh;
    right: 0;
}

.sub-heading-yellow-dot {
    position: absolute;
    top: 170vh;
    left: 6%;
}

.sub-heading-blue-dot {
    position: absolute;
    top: 140vh;
    right: 10%;
}

/*Navigation*/
.yellow-button {
    background-color: #FFE818;
    font-size: 1.2em;
    color: black;
    text-decoration: none;
    font-weight: 600;
    padding: 3% 12%;
}

.blue-button {
    background-color: #273883;
    font-size: 1.4em;
    color: white;
    text-decoration: none;
    padding: 3% 12%;
    font-weight: 600;
}

/*Service Section*/
section.service-section {
    margin-top: 8rem;
}

h1.service-heading,
h1.client-heading {
    font-size: 2.5em;
    font-family: "Montserrat";
    text-align: center;
    font-weight: 600;
    font-style: bold;
}

h1.service-heading {
    color: #273883;
}

div.service-element {
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.service-image {
    max-width: 50%;
    min-width: 550px;
}

.shadow {
    box-shadow: 1px 27px 30px rgba(0, 0, 0, 0.2);
}

div.service-description {
    padding: 5%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    line-height: 25px;
}

div.service-description>p {
    max-width: 90%;
}

div.service-description>h3 {
    font-size: 1.8em;
    font-family: "Montserrat", Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: #5E5E5E;
}

div.service-element.reverse {
    flex-direction: row-reverse;
}

img.img-responsive {
    width: 100%;
    min-width: 100%;
}

/*Service Decorations*/
.service-dots {
    position: absolute;
    left: -2%;
    top: 300vh;
    z-index: -1;
}

/*Client Section*/
section.black {
    color: white;
    padding: 10%;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.client-service-icons {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 50%;
    flex-wrap: wrap;
}

.client-service-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-service-text,
.client-service-label {
    text-align: center;
}

.client-service-text {
    line-height: 30px;
    max-width: 70%;
}

/*Client Decoration*/
.client-zagged {
    position: absolute;
    top: 625vh;
    left: 0;
}

/**Testimonials Section*/
section.testimonials-section {
    padding: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.logo-set {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 75%;
}

.logo-set>img {
    padding: 5% 2%;
    max-width: 150px;
    filter: grayscale(1);
}

.logo-set>img:hover {
    filter: grayscale(0);
}

.testimonials {
    display: flex;
    padding: 2%;
    width: 90%;
    align-items: center;
    justify-content: space-around;
}

.testimonial-1,
.testimonial-2,
.testimonial-3 {
    max-width: 300px;
    padding: 5%;
    color: #555555;
    text-align: justify;
    border-radius: 35px;
    max-height: 600px;
    overflow: hidden;
}

.testimonial-1,
.testimonial-3 {
    font-size: 0.85em;
    padding: 2.5%;
    max-width: 200px;
    max-height: 400px;
}

.testimonial-1>p,
.testimonial-3>p {
    font-size: 0.8em;
}

.testimonials-section>h1 {
    font-family: "Montserrat SemiBold";
    padding: 5%;
    color: #273883;
    font-weight: 800;
}

.testimonial-name {
    font-family: "Montserrat SemiBold";
    color: #53BAE7;
    text-align: center;
}

/*Footer*/
footer {
    min-height: 50vh;
    padding: 5%;
    background-color: #273883;
    color: white;
    line-height: 30px;
}

.footer-element>.logo>a {
    color: white;
}
footer > h5{
    text-align: center;
}

.footer-elements,
.footer-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-element {
    align-items: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
}

.footer-element>p {
    padding: 20px;
}

.footer-element>a {
    padding: 10px;
    color: white;
    text-decoration: none;
}

.footer-element.links {
    max-height: 210px;
    min-width: 150px;
}

.footer-element .social-icons {
    padding: 5%;
}

.social-icons>a {
    text-decoration: none;
}

.footer-element>form {
    display: flex;
    flex-direction: column;
    padding: 5%;
}

.footer-element>form>* {
    margin: 10px;
    padding: 5%;
}

.footer-element>form>input {
    padding: 6%;
    border-radius: 5px;
    border: #53BAE7 1px solid;
}

.footer-element>form>button {
    display: inline-flex;
    justify-content: space-evenly;
    font-family: "Montserrat";
    background-color: #53BAE7;
    border: none;
    color: white;
}

.footer-element>form>button:hover,
.footer-element>form>button:focus {
    background-color: white;
    border: none;
    color: #53BAE7;
}
.footer-element>form>button:hover>svg>path{
    fill: #53BAE7;
}

@media screen and (max-width:600px) {
    .welcome {
        text-align: left;
    }

    .welcome-heading {
        padding-top: initial;
        margin-top: 0;
    }

    .sub-heading-contents {
        font-size: 0.5em;
        padding: 30px;
        max-width: fit-content;
    }

    div.service-element,
    div.service-element.reverse {
        flex-direction: column;
    }

    div.service-image {
        min-width: 100%;
    }

    .client-zagged {
        display: none;
    }

    .logo-set>img {
        max-width: 40px;
    }

    .testimonials {
        flex-direction: column;
    }

    .testimonial-1,
    .testimonial-2,
    .testimonial-3 {
        padding: 5%;
    }

    .footer-elements {
        flex-direction: column;
        align-items: flex-start;
    }
}

`