import React from 'react';
import {Style} from './Style';
import Card from './Card';
import data from './data';


class Partners extends React.Component{
    constructor(){
        super()
        this.state = {
            display: data,
        }
    }
    render (){
        const {display} = this.state;
    return(
        <Style>
            <div className="welcome-elements">
                <div className = "contain">
                    <h1 className="welcome-heading">Our Advisory Board</h1>
                </div>
            </div>
            
            <div className = "container">
                <div className = "gallery-container">
                    {display.map(images => <Card key = {data.id}  props = {images}></Card>)}
                </div>
            </div>
        </Style>
    )
}
}

export default Partners