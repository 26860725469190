import React from 'react';
import { Style } from './Style';


function Contact() {
    return (
        <Style>
            <section>
                <div className="welcome-elements">
                    <div className="contain">
                        <h1 className="welcome-heading">Speak With Us</h1>
                        <p className="welcome-sub-heading">Today</p>
                        <div className="container">
                            <div className="details">
                                <svg width="57" height="58" viewBox="0 0 57 58" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M56.2902 4.30803C56.8125 3.30357 56.6116 2.09821 55.808 1.33482C55.3259 0.812496 54.683 0.571427 54 0.571427C53.5982 0.571427 53.1964 0.651782 52.8348 0.852677L1.40625 26.567C0.361607 27.0893 -0.200893 28.2946 0.0803571 29.4598C0.361607 30.625 1.36607 31.4286 2.57143 31.4286H25.7143V54.5714C25.7143 55.7768 26.5179 56.7812 27.683 57.0625C27.8839 57.1027 28.0848 57.1429 28.2857 57.1429C29.25 57.1429 30.1339 56.6205 30.5759 55.7366L56.2902 4.30803Z" fill="black" />
                                </svg>
                                <p>33b Alfred Rewane Ikoyi Lagos</p>
                                <p>7, Ibiyinka Olorunbe Victoria Island Lagos</p>
                            </div>
                            <div className="details">
                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.6533 28.7733C21.4933 36.32 27.68 42.48 35.2267 46.3467L41.0933 40.48C41.8133 39.76 42.88 39.52 43.8133 39.84C46.8 40.8267 50.0267 41.36 53.3333 41.36C54.8 41.36 56 42.56 56 44.0267V53.3333C56 54.8 54.8 56 53.3333 56C28.2933 56 8 35.7067 8 10.6667C8 9.2 9.2 8 10.6667 8H20C21.4667 8 22.6667 9.2 22.6667 10.6667C22.6667 14 23.2 17.2 24.1867 20.1867C24.48 21.12 24.2667 22.16 23.52 22.9067L17.6533 28.7733Z" fill="black" />
                                </svg>
                                <p>+234 900 0012 200,</p>
                                <p>+234 900 0012 200,</p>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </Style>
    )
}

export default Contact