import React from 'react';
import {StyleCard} from './Style';

function Card ({props}) {
    return(
        <StyleCard>
            <div className = 'contain-cards'>
                <img src = {props.img} alt = '.' />
            </div>
        </StyleCard>
    )
}

export default Card