import React from 'react';
import {Style} from './Style';
import Slide from 'react-reveal/Slide';

function HomeWelcome() {
    return(
        <Style>
            <div className="welcome">
                <div className="welcome-image">
                    <svg className="swing-top-bck" width="108" height="278" viewBox="0 0 108 278" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M87.836 277.866C93.4565 277.94 99.8728 277.692 103.454 273.563C104.299 272.593 104.921 271.4 104.797 270.156C104.498 267.47 101.116 266.326 98.3309 265.879C82.0665 263.292 65.429 264.287 48.9905 265.332C38.7195 265.978 28.3242 266.625 18.5258 269.634C13.4276 271.201 9.34913 275.279 15.8151 277.07C22.0573 278.811 31.6319 277.144 38.1724 277.219C54.6856 277.418 71.2732 277.642 87.836 277.866Z"
                            fill="url(#paint0_linear)" />
                        <path
                            d="M87.836 277.866C93.4565 277.94 99.8728 277.692 103.454 273.563C104.299 272.593 104.921 271.4 104.797 270.156C104.498 267.47 101.116 266.326 98.3309 265.879C82.0665 263.292 65.429 264.287 48.9905 265.332C38.7195 265.978 28.3242 266.625 18.5258 269.634C13.4276 271.201 9.34913 275.279 15.8151 277.07C22.0573 278.811 31.6319 277.144 38.1724 277.219C54.6856 277.418 71.2732 277.642 87.836 277.866Z"
                            fill="url(#paint1_linear)" />
                        <path
                            d="M64.633 261.154C64.633 261.278 64.6331 261.402 64.7077 261.502C64.8072 261.651 65.0061 261.676 65.1802 261.701C66.9957 261.85 68.8609 261.676 70.6266 261.178C70.7509 261.154 70.9002 261.104 70.9748 261.004C71.0742 260.905 71.124 260.756 71.1489 260.606C71.4473 259.239 71.4721 257.796 71.2234 256.428C71.1985 256.304 71.1737 256.18 71.0742 256.08C70.9747 255.981 70.8256 255.981 70.6764 255.981C69.2588 255.931 66.2745 255.533 65.031 256.279C63.9616 256.901 64.6082 260.01 64.633 261.154Z"
                            fill="#F9E9DE" />
                        <path
                            d="M36.158 261.303C36.1828 261.427 36.1828 261.551 36.2823 261.651C36.3818 261.775 36.5558 261.775 36.7299 261.775C38.5454 261.825 40.3608 261.825 42.1763 261.8C42.3255 261.8 42.4995 261.8 42.6239 261.701C42.7731 261.576 42.8229 261.377 42.8478 261.203C43.0467 259.811 43.1213 258.418 43.0964 257C41.3804 257.025 39.6644 257.075 37.9236 257.1C36.7547 257.125 36.3818 257 36.1331 258.07C35.8844 259.04 36.0585 260.308 36.158 261.303Z"
                            fill="#F9E9DE" />
                        <path
                            d="M60.3307 242.129C60.8032 246.605 61.3006 251.131 62.4694 255.459C62.6684 256.229 62.9421 257.05 63.5389 257.498C64.0612 257.871 64.7077 257.871 65.3294 257.871C66.8962 257.846 68.463 257.821 70.0297 257.821C70.6017 257.821 71.1985 257.796 71.671 257.473C72.6161 256.851 72.815 255.434 72.8896 254.19C75.1527 223.875 78.908 193.584 86.4682 164.413C87.9106 158.817 89.5271 153.197 89.925 147.377C90.3229 141.558 89.4276 135.39 86.2941 130.765C81.942 124.324 74.2823 122.16 67.1697 121.712C63.5637 121.488 59.8831 121.588 56.3517 122.508C52.1986 123.578 52.7456 125.393 52.87 129.919C53.1187 138.971 53.4669 148.024 53.8897 157.076C54.7352 175.156 55.904 193.236 57.4211 211.266C58.2915 221.562 59.2613 231.858 60.3307 242.129Z"
                            fill="#3D322B" />
                        <path
                            d="M44.5389 255.384C44.4145 256.031 44.2902 256.727 43.8923 257.199C43.5192 257.622 42.9721 257.796 42.4498 257.921C40.0126 258.517 37.5258 258.692 35.0389 258.418C32.7012 228.6 29.1946 198.906 24.5689 169.436C23.4249 162.15 22.2063 154.838 22.2063 147.427C22.2063 140.016 23.5244 132.456 27.0558 126.239C29.866 121.314 34.0441 117.435 38.7443 114.998C43.5192 112.536 55.8046 110.198 56.6253 118.703C57.6698 129.447 57.7941 140.439 57.5454 151.257C57.048 173.739 54.3373 196.046 50.8805 218.155C48.9158 230.614 46.7274 242.999 44.5389 255.384Z"
                            fill="#3D322B" />
                        <path
                            d="M74.4813 44.2946C75.4761 44.5931 76.247 45.3143 76.9931 46.0106C78.2117 47.1297 79.4055 48.2737 80.6241 49.3928C83.5338 52.1035 86.4683 54.8641 88.6817 58.1717C90.1738 60.4099 91.3178 62.8719 92.7602 65.135C95.6202 69.6612 99.6489 73.3666 102.484 77.9177C103.752 79.957 104.747 82.1455 105.667 84.3589C107.184 88.0892 108.428 92.1926 107.383 96.0722C106.886 97.9623 105.667 99.8274 103.777 100.325C102.459 100.673 101.042 100.25 99.823 99.6782C96.4656 98.1114 93.8295 95.3261 91.5415 92.4164C81.8674 80.156 76.8687 65.1349 70.0794 51.258C69.3334 49.7409 68.09 48.2239 69.1345 46.6074C70.1542 44.991 72.5913 43.7475 74.4813 44.2946Z"
                            fill="#5E5E5E" />
                        <path
                            d="M36.183 45.8614C35.1882 45.4138 34.069 45.3391 32.9997 45.4386C29.1698 45.8117 25.8373 48.3732 23.4498 51.3824C21.0375 54.3915 19.3714 57.923 17.3321 61.1808C13.5023 67.2986 8.37928 72.5212 4.62404 78.6638C3.50492 80.4793 2.48525 82.4688 2.41065 84.5827C2.36091 86.0251 2.73396 87.4426 3.20648 88.8104C3.65412 90.029 4.25099 91.3222 5.39497 91.9439C6.68817 92.6651 8.37928 92.2921 9.59786 91.4465C10.8165 90.601 11.6619 89.3327 12.4826 88.0893C14.9447 84.3589 17.4066 80.6533 19.8687 76.923C23.0271 72.1232 26.2105 67.3235 29.9657 62.9714C31.8806 60.7581 33.9696 58.6442 35.5613 56.1821C37.2026 53.6206 40.1869 47.7017 36.183 45.8614Z"
                            fill="#5E5E5E" />
                        <path
                            d="M65.4288 45.19C66.3987 44.6926 67.5925 44.1206 68.4878 44.7423C68.9106 45.0407 69.0846 45.563 69.3333 46.0355C83.8569 74.461 77.4655 70.7057 85.1998 89.3575C86.8412 93.2869 89.6514 112.337 90.6711 116.465C92.5611 124.199 92.1632 128.701 91.7404 136.659C91.6658 137.952 91.5912 139.27 91.0193 140.439C90.3727 141.682 89.2038 142.578 88.0349 143.348C81.2954 147.75 80.2758 149.516 72.2182 149.69C64.1606 149.864 56.1776 152.127 48.2443 150.784C44.0663 150.088 39.8883 149.392 35.7103 148.695C29.8412 147.725 23.4248 146.432 19.7442 141.732C18.0531 139.568 17.133 136.882 16.4864 134.221C12.8555 119.275 16.4117 103.583 20.6644 88.7856C24.5439 75.207 28.5728 61.2803 34.0938 48.2488C37.1029 41.1363 41.5047 46.4334 47.3987 47.6271C53.4419 48.8706 59.9576 48.0748 65.4288 45.19Z"
                            fill="#C4C4C4" />
                        <path
                            d="M41.8281 60.5093C42.6737 62.4491 43.5192 64.4138 43.9668 66.4779C44.4145 68.5918 44.4395 70.7554 44.4146 72.919C44.2902 82.9413 43.3699 92.9636 41.6291 102.837C40.9576 106.642 41.0074 110.422 40.8582 114.276C40.7836 116.639 40.1122 119.026 39.6894 121.364C39.2169 123.975 39.9629 128.377 39.1671 130.914C37.0035 137.852 37.5507 144.244 35.5363 151.207C33.1737 149.79 31.2836 149.367 29.5427 149.267C27.8268 149.168 26.0611 148.347 24.3452 148.322C20.1672 148.248 16.1134 145.786 14.1238 142.13C12.2089 138.623 12.1841 134.396 12.3084 130.392C12.582 120.618 13.4275 110.844 15.6906 101.32C16.7351 96.9177 18.078 92.5657 19.421 88.2384C22.6042 77.9675 25.7876 67.6965 28.9957 57.4504C29.6672 55.2868 31.9303 45.4884 35.5612 46.8562C37.0036 47.4033 38.1226 51.9543 38.7195 53.3221C39.7142 55.7096 40.7836 58.097 41.8281 60.5093Z"
                            fill="#666666" />
                        <path
                            d="M37.5756 15.4464C35.2876 20.271 35.2626 25.8417 35.76 31.1637C35.9092 32.8548 36.1331 34.5459 36.73 36.1127C38.819 41.4844 45.0363 44.046 50.7811 44.4936C53.5167 44.6926 56.3019 44.5682 58.8883 43.648C62.5441 42.3548 65.5533 39.5446 67.5429 36.2122C69.5324 32.8797 70.6018 29.0748 71.124 25.2201C71.5219 22.3352 71.6213 19.3012 70.7011 16.5407C69.3085 12.3378 65.6279 9.12966 61.4748 7.58777C52.8949 4.40452 41.7785 6.54327 37.5756 15.4464Z"
                            fill="#67302D" />
                        <path
                            d="M47.7719 17.7095C44.5886 18.4059 41.3058 18.5054 38.0728 18.58C36.2077 18.6297 34.3426 18.6794 32.552 18.2318C30.7614 17.7841 29.0205 16.7397 28.1501 15.0983C29.1697 15.72 30.5624 15.148 31.3085 14.2279C32.0795 13.3077 32.4276 12.114 32.9747 11.0695C33.5467 9.97529 34.3674 9.00539 35.213 8.1101C41.9028 1.17161 52.5468 -1.68838 61.823 0.997483C65.8269 2.16633 69.6069 4.32998 72.1187 7.63758C74.6305 10.9452 75.7496 15.4464 74.4315 19.4006C74.1082 20.3705 73.6109 21.3404 72.8151 21.987C71.7955 22.8077 70.3779 23.0067 69.0599 22.8575C65.7025 22.4844 62.942 20.097 60.4551 17.809C58.764 16.2672 57.1475 13.7803 54.7352 14.7999C52.3726 15.7947 50.3334 16.9635 47.7719 17.7095Z"
                            fill="#1D262D" />
                        <path
                            d="M42.7482 58.3706C43.6186 60.1612 44.4891 61.9767 44.9865 63.9165C45.5336 66.0801 45.6083 68.3183 45.658 70.5565C46.0559 94.9034 41.0075 127.805 37.3766 151.879C36.4315 151.182 35.561 151.704 34.1683 150.511C33.8948 146.631 37.0284 134.172 38.0232 130.392C41.7038 116.564 39.0178 101.693 42.2757 87.766C42.6985 85.9008 43.2456 84.0604 43.2456 82.1455C43.2456 80.1808 42.6737 78.2659 42.6986 76.3012C42.7235 73.69 43.7679 71.1036 43.5192 68.5172C43.2705 65.9308 41.7535 63.6677 40.5846 61.33C39.1919 58.5447 36.1081 51.4818 37.8987 48.5473C39.9131 51.4072 41.2063 55.1874 42.7482 58.3706Z"
                            fill="#53BAE7" />
                        <path
                            d="M31.582 58.7187C33.5467 59.241 35.5113 59.7633 37.476 60.2607C37.3268 63.2947 36.5807 66.279 35.3124 69.0146C35.2129 69.2633 35.0885 69.5119 35.1134 69.7606C35.1383 70.0093 35.3124 70.2083 35.4616 70.3824C37.9983 73.4164 40.51 76.4256 43.0467 79.4596C43.6187 73.889 44.1409 68.0944 42.4249 62.7724C41.6789 60.4844 40.5598 58.3954 39.4904 56.2567C38.7195 54.7148 38.0231 51.2829 36.8543 50.1886C32.8752 46.3836 33.2483 59.1664 31.582 58.7187Z"
                            fill="#4D4D4D" />
                        <path
                            d="M70.1292 44.0709C72.1685 52.974 79.6541 60.2109 80.3753 69.313C80.4996 70.9544 81.4943 72.919 81.8923 74.5107C82.3648 76.3758 82.3897 77.694 83.3098 79.3851C86.2444 84.9309 86.8165 91.3968 88.3832 97.4649C89.2536 100.847 90.4473 104.155 91.2929 107.562C92.9342 114.202 93.2327 121.091 93.3819 127.905C93.4565 131.511 93.5063 135.092 93.5311 138.698C93.5311 139.742 93.5311 140.812 93.1332 141.782C92.7601 142.727 92.039 143.498 91.3178 144.194C84.4539 150.809 74.6056 154.191 65.1305 153.172C65.0807 151.406 64.9315 149.615 64.5336 147.875C64.1855 146.407 63.6632 144.99 63.3151 143.523C62.8177 141.384 62.7181 139.17 62.6187 136.982C62.3202 129.844 62.0218 122.732 61.7234 115.595C61.3752 107.214 61.0271 98.8327 59.8333 90.5513C59.4852 88.2136 59.0874 85.8759 59.162 83.4885C59.2117 81.5735 59.5598 79.6834 59.7339 77.7685C60.0572 74.1127 59.6841 70.4321 60.281 66.8012C61.027 62.1258 63.3648 57.8484 64.1109 53.173C64.7575 48.8706 65.6528 46.1599 70.1292 44.0709Z"
                            fill="#666666" />
                        <path
                            d="M58.7143 59.1913C57.0729 64.4635 55.4315 69.8353 55.5559 75.3314C55.6056 78.067 56.1279 80.7777 56.5009 83.4885C57.5205 90.427 57.8438 97.4152 58.192 104.403C58.7889 116.763 59.3857 129.098 60.0074 141.458C60.082 143.1 61.4996 150.411 62.37 151.829C63.2404 153.246 64.26 152.774 65.7273 152.003C64.0859 139.146 62.743 122.011 61.8726 109.079C61.1265 98.1363 60.4053 87.1939 59.6592 76.2266C59.5348 74.5107 59.4354 72.7699 59.6592 71.0539C59.9576 68.8654 60.7784 66.7764 61.4996 64.6874C63.4892 58.9178 65.3045 52.4517 65.8765 46.3837C60.7534 48.8208 60.2313 54.292 58.7143 59.1913Z"
                            fill="#53BAE7" />
                        <path
                            d="M70.4277 60.2607C68.2889 62.5984 65.9762 64.7371 63.5141 66.7018C64.8819 67.5225 66.3243 68.2437 67.7915 68.8903C65.8766 70.8301 64.0861 72.8942 62.4199 75.0329C60.8531 77.0722 59.3609 79.4099 59.5599 81.9714C59.8334 79.2109 58.2419 76.6245 57.7694 73.8889C56.8989 68.6913 60.1567 63.8169 61.8478 58.8182C62.4695 56.9779 63.1161 48.1494 65.7771 48.9701C66.6724 49.2436 70.7758 59.8628 70.4277 60.2607Z"
                            fill="#4D4D4D" />
                        <path
                            d="M32.0794 122.682C32.552 123.005 33.0245 123.354 33.1986 123.901C33.3727 124.423 33.0244 125.169 32.4773 125.144C27.9511 122.956 23.8974 119.897 19.8935 116.863C18.9733 116.166 15.4917 114.45 16.6605 113.058C17.6304 111.914 20.2666 114.525 21.0624 115.072C24.7182 117.609 28.3988 120.146 32.0794 122.682Z"
                            fill="#4D4D4D" />
                        <path
                            d="M80.798 114.252C81.7927 113.506 82.7876 112.759 83.658 111.864C84.6776 110.845 85.6475 109.576 87.0899 109.377C86.891 109.999 87.4381 110.646 87.4381 111.292C87.4381 111.988 86.8163 112.511 86.2692 112.934C81.7182 116.44 77.1422 119.971 72.5912 123.478C71.8451 124.05 71.1489 124.498 70.2038 124.448C68.6122 121.265 78.4851 115.992 80.798 114.252Z"
                            fill="#4D4D4D" />
                        <path
                            d="M6.58858 90.4767C6.88701 92.0435 7.1854 93.66 8.10556 94.9034C9.24954 96.4702 11.1893 97.1914 13.0296 97.2162C14.8699 97.2411 15.9642 96.694 17.7299 96.1469C23.1265 94.4806 29.0454 93.6599 34.4421 91.9937C35.3871 91.6953 36.3818 91.372 37.0284 90.5513C38.2221 88.9846 37.2025 86.7712 36.7051 84.8066C34.0192 74.2869 32.7509 63.9413 29.5427 53.9936C29.2692 53.0984 29.2443 52.7004 28.6226 51.8798C28.349 51.5067 27.7274 51.3078 27.2797 51.3327C22.2562 51.7555 17.4315 52.7254 12.7561 54.516C9.74691 55.6599 3.62908 56.5303 0.918342 58.9178C-1.66805 61.1809 1.96298 69.4871 2.60958 73.0185C3.70382 78.813 5.51921 84.6325 6.58858 90.4767Z"
                            fill="#EFEFEF" />
                        <path
                            d="M21.112 71.8249C20.2416 70.5317 19.2966 69.2633 18.3267 68.0447C18.0282 67.6717 17.4314 67.9204 17.3071 68.2934C16.4864 70.7803 15.6657 73.4662 15.5164 76.1023C15.3672 76.3759 15.3425 76.6992 15.4668 76.9728C15.4668 77.0474 15.4419 77.1468 15.4419 77.2214C15.4419 77.4204 15.6906 77.4452 15.7901 77.296C15.9145 77.3458 16.0388 77.3955 16.2128 77.3706C17.8791 77.1717 19.5951 76.9976 21.2116 76.5749C21.9328 76.3759 22.9524 76.1521 23.027 75.2568C23.1016 74.1874 21.684 72.6455 21.112 71.8249ZM20.1173 75.207C18.8739 75.3811 17.6304 75.5055 16.3869 75.6298C17.0087 73.69 17.5061 71.7005 18.1278 69.7607C19.0977 71.0539 20.0427 72.3969 20.8883 73.7895C21.4851 74.8092 21.2862 75.0578 20.1173 75.207Z"
                            fill="white" />
                        <path
                            d="M5.32029 84.1599C5.56898 83.563 5.86746 82.8915 6.46432 82.618C7.06118 82.3444 7.95639 82.8169 7.78231 83.4635C9.42367 83.3889 10.1697 80.9766 11.8111 80.8771C12.4577 80.8522 13.0546 81.2005 13.5271 81.6232C14.2731 82.2947 14.8452 83.1403 15.1685 84.1102C15.3675 84.6821 15.4669 85.279 15.3177 85.8509C15.0442 86.9203 14.0494 87.5918 13.1043 88.1389C10.9656 89.4321 8.80201 90.7004 6.61353 91.919C6.29023 92.0931 5.94195 92.2921 5.56892 92.3418C4.94719 92.4164 4.32555 92.0683 3.95251 91.5709C2.21167 89.3575 4.425 86.3483 5.32029 84.1599Z"
                            fill="#67302D" />
                        <path
                            d="M107.035 93.0133C106.14 91.5709 104.374 90.9243 102.708 90.6259C98.5299 89.8798 94.2027 90.7502 90.2733 92.3667C86.344 93.9832 82.7877 96.3209 79.2314 98.6337C75.5259 101.071 71.8203 103.483 68.1148 105.92L65.7274 107.487C64.8569 107.661 64.285 108.581 64.2353 109.477C64.1855 110.372 64.5337 111.242 64.8819 112.063C65.802 114.276 66.6973 116.49 67.6174 118.703C72.5664 117.46 77.5652 116.241 82.3152 114.351C87.662 112.262 92.6608 109.377 97.56 106.368C100.594 104.503 103.628 102.513 105.817 99.728C107.284 97.7882 108.303 95.0774 107.035 93.0133Z"
                            fill="#5E5E5E" />
                        <path
                            d="M49.7115 120.469C49.8359 121.066 49.9354 121.663 50.2587 122.185C50.8059 123.155 51.9001 123.702 53.0192 123.876C54.1134 124.05 55.2326 123.876 56.3517 123.702C57.0232 123.602 57.6946 123.503 58.3412 123.403C58.764 123.329 59.1868 123.279 59.5847 123.08C60.654 122.583 61.1514 121.314 61.4001 120.146C62.4943 115.023 60.8032 109.725 60.6291 104.478C60.6291 104.229 60.6043 103.981 60.5048 103.757C60.2561 103.284 59.6095 103.235 59.0872 103.259C57.1226 103.309 55.1829 103.558 53.268 103.931C51.6017 104.279 49.6867 104.652 48.1697 105.448C47.1003 106.02 46.7024 106.89 46.976 108.059C47.3988 109.974 48.0206 111.839 48.4433 113.754C48.891 116.017 49.2888 118.255 49.7115 120.469Z"
                            fill="#F5F5F5" />
                        <path
                            d="M60.5795 102.339C60.2562 102.289 59.908 102.339 59.5598 102.389C59.3857 102.414 59.2365 102.439 59.0624 102.464C59.0126 102.165 58.8884 101.867 58.7143 101.643C58.6646 101.568 58.5899 101.519 58.5153 101.469C58.4159 101.444 58.3164 101.444 58.2418 101.469C57.5455 101.593 56.874 101.742 56.1776 101.941C56.0533 101.966 55.9289 102.016 55.8543 102.115C55.7548 102.24 55.7052 102.762 55.7549 103.011C54.4617 103.235 53.1684 103.433 51.9001 103.657C50.6069 103.881 49.3386 104.105 48.0454 104.354C47.2993 104.503 46.23 104.577 45.9316 105.1C45.7077 105.523 45.7575 106.592 46.7025 106.617C46.8517 106.617 47.0009 106.592 47.1501 106.567C51.2535 105.896 55.3819 105.224 59.4853 104.553C59.8334 104.503 60.1815 104.428 60.4799 104.304C61.0271 104.055 61.2509 103.632 61.3007 103.259C61.3753 102.886 61.2012 102.439 60.5795 102.339Z"
                            fill="#454545" />
                        <path
                            d="M57.9682 110.645C57.1972 110.745 56.3765 110.869 55.7797 111.367C55.1828 111.839 54.8844 112.834 55.3818 113.406C54.0389 114.127 52.3478 114.202 51.1789 115.172C50.2836 115.943 49.8609 117.211 49.9604 118.38C50.0598 119.574 50.6317 120.668 51.4276 121.588C52.0742 122.309 52.8949 122.956 53.8399 123.105C54.8596 123.279 55.8792 122.881 56.8243 122.508C59.2863 121.538 61.7483 120.543 64.2352 119.574C65.7274 118.977 67.4682 118.081 67.5925 116.49C67.6422 115.818 67.3936 115.147 67.1449 114.525C66.6475 113.232 66.1253 110.297 64.7077 109.775C62.9918 109.129 59.8582 110.645 57.9682 110.645Z"
                            fill="#67302D" />
                        <path
                            d="M24.6684 265.804C26.5833 265.083 28.6971 265.307 30.7612 265.332C31.9798 265.357 33.3477 265.207 34.1187 264.287C34.7653 263.516 34.815 262.397 34.7404 261.377C34.7155 261.104 34.7155 260.805 34.8647 260.607C35.0388 260.383 35.3621 260.333 35.6605 260.308C38.1226 260.109 40.5846 259.885 43.0715 259.686C43.3202 259.662 43.5689 259.661 43.7679 259.786C44.0166 259.96 44.0414 260.333 44.0414 260.631C44.0663 263.541 44.0663 266.451 44.0912 269.336C44.0912 269.684 44.0912 270.032 43.942 270.33C43.6187 270.977 42.7979 271.151 42.1016 271.226C36.5557 271.947 30.9851 272.544 25.3896 272.991C23.3752 273.141 20.1422 273.414 20.739 270.604C21.2116 268.465 22.6291 266.6 24.6684 265.804Z"
                            fill="#4A4E31" />
                        <path
                            d="M70.5519 259.313C71.1239 259.288 71.7954 259.338 72.1436 259.786C72.3426 260.059 72.3923 260.407 72.4171 260.731C72.5166 261.676 72.641 262.646 72.7404 263.591C72.815 264.163 72.8897 264.76 73.2379 265.232C73.586 265.68 74.1332 265.928 74.6803 266.078C76.2222 266.525 77.8386 266.426 79.4302 266.525C81.0219 266.65 82.713 267.048 83.7823 268.216C84.5533 269.062 84.9015 270.231 84.9513 271.375C84.9513 271.599 84.9512 271.822 84.8517 272.021C84.6527 272.394 84.1802 272.519 83.7575 272.569C82.4891 272.718 81.196 272.693 79.9277 272.643C77.3164 272.569 74.6803 272.494 72.0691 272.419C69.5076 272.345 66.9211 272.27 64.3844 271.748C64.1109 271.698 63.8125 271.623 63.6633 271.4C63.5389 271.25 63.5141 271.027 63.4892 270.853C63.2654 268.938 63.0415 266.998 62.8177 265.083C62.6685 263.815 62.1214 262.024 62.8426 260.855C64.086 258.766 68.5873 259.313 70.5519 259.313Z"
                            fill="#4A4E31" />
                        <defs>
                            <linearGradient id="paint0_linear" x1="12.4433" y1="271.05" x2="104.808" y2="271.05"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#4D4D4D" />
                                <stop offset="1" />
                            </linearGradient>
                            <linearGradient id="paint1_linear" x1="12.4433" y1="271.05" x2="104.808" y2="271.05"
                                gradientUnits="userSpaceOnUse">
                                <stop />
                                <stop offset="1" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <svg  className="swing-top-bck" width="165" height="333" viewBox="0 0 165 333" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M22.8095 332.842C29.4129 333.083 36.0164 332.992 42.6199 332.932C61.0433 332.721 79.4667 332.54 97.8901 332.329C100.755 332.299 104.463 331.756 104.644 329.826C104.825 328.078 101.9 326.962 99.4279 326.419C90.5931 324.489 81.3061 324.097 72.1396 323.736C58.6613 323.193 45.1527 322.741 31.6744 322.379C23.2618 322.137 14.6081 321.957 6.76834 323.856C2.48664 324.881 -3.81546 329.344 3.02923 331.575C4.92886 332.178 8.72811 331.937 10.7483 332.118C14.7888 332.42 18.7992 332.691 22.8095 332.842Z"
                            fill="url(#paint0_linear)" />
                        <path opacity="0.22"
                            d="M22.8095 332.842C29.4129 333.083 36.0164 332.992 42.6199 332.932C61.0433 332.721 79.4667 332.54 97.8901 332.329C100.755 332.299 104.463 331.756 104.644 329.826C104.825 328.078 101.9 326.962 99.4279 326.419C90.5931 324.489 81.3061 324.097 72.1396 323.736C58.6613 323.193 45.1527 322.741 31.6744 322.379C23.2618 322.137 14.6081 321.957 6.76834 323.856C2.48664 324.881 -3.81546 329.344 3.02923 331.575C4.92886 332.178 8.72811 331.937 10.7483 332.118C14.7888 332.42 18.7992 332.691 22.8095 332.842Z"
                            fill="url(#paint1_linear)" />
                        <path
                            d="M16.0252 91.5286C14.216 95.4787 12.5276 99.6398 12.5577 103.982C12.5879 108.324 14.7287 112.937 18.739 114.626C19.3119 114.867 19.915 115.048 20.5181 114.988C21.2418 114.927 21.8749 114.535 22.4779 114.113C26.7898 111.007 29.2323 105.972 31.8254 101.328C38.3987 89.5989 47.0827 78.8946 51.2438 66.14C51.4851 65.3862 51.7262 64.5419 51.3946 63.7881C51.1835 63.3056 50.7312 62.9438 50.3091 62.6121C48.4095 61.1949 46.1479 60.1094 43.7658 60.1999C41.4139 60.2903 38.9716 61.798 38.4289 64.0896C38.2178 62.4312 38.6399 60.7728 39.0922 59.1747C39.5445 57.5766 40.0269 55.9483 39.9967 54.2598C39.9364 52.1792 37.5242 47.7166 34.9612 49.9781C34.117 50.7017 33.9964 52.2998 33.6949 53.2949C33.1521 55.0739 32.4887 56.7926 31.7952 58.5113C28.9307 65.7781 25.3425 72.7133 21.8146 79.6484C19.8547 83.5985 17.8645 87.5183 16.0252 91.5286Z"
                            fill="#EBEBEB" />
                        <path
                            d="M33.3028 41.1131C33.0013 42.47 33.1821 43.8872 33.3932 45.244C33.6947 47.0532 34.0567 48.9227 34.9914 50.5208C35.896 52.1189 37.4337 53.4758 39.273 53.717C40.2982 53.8678 41.414 53.5964 42.1678 52.8727C43.193 51.8777 43.3136 50.3097 43.3136 48.8925C43.2835 46.6009 43.0422 44.2792 42.5598 42.0177C42.409 41.3242 42.2581 40.6005 41.8661 39.9975C40.9013 38.3994 38.4891 37.5249 36.6498 37.6154C34.9311 37.736 33.6948 39.515 33.3028 41.1131Z"
                            fill="#67302D" />
                        <path
                            d="M46.1177 46.5105C45.4242 44.5807 44.7307 42.6509 44.0372 40.691C43.6753 39.6658 43.3134 38.6707 42.8008 37.7059C41.8962 36.0173 40.5394 34.57 38.9112 33.5448C38.097 33.0322 37.0417 32.6402 36.2577 33.1829C35.8356 33.3035 35.4737 33.5146 35.1119 33.7558C34.3882 34.2081 33.6948 34.6906 32.9711 35.1429C32.6696 35.3238 32.368 35.5349 32.1268 35.8062C31.7348 36.2887 31.6141 36.9219 31.6744 37.5249C31.7347 38.128 31.9759 38.7009 32.2473 39.2437C33.6947 42.2288 35.9864 44.7315 37.6146 47.6261C38.2177 48.7116 38.7604 49.8273 39.4539 50.8826C40.268 52.0888 41.2631 53.114 42.2883 54.1391C42.3787 54.2296 42.4993 54.3502 42.6199 54.3804C42.8611 54.4407 43.1025 54.3201 43.3135 54.1693C44.1578 53.5964 45.0322 53.0536 45.8765 52.4807C46.1478 52.2998 46.4494 52.0888 46.6605 51.8174C46.8414 51.546 46.9318 51.1842 46.9619 50.8525C47.1428 49.4052 46.6303 47.9277 46.1177 46.5105Z"
                            fill="#EDEDED" />
                        <path
                            d="M39.8158 50.4002C40.3585 51.5159 40.9314 52.7823 42.1074 53.2346C43.4643 53.7472 44.9117 52.8727 46.1178 52.0586C46.6304 51.6968 47.143 51.3349 47.4445 50.7922C47.8968 49.9479 47.6555 48.9227 47.3841 48.0181C46.7208 45.7567 45.8463 43.5555 44.9116 41.4147C44.1276 39.6055 43.3135 37.7963 42.439 36.0173C42.1677 35.4745 41.8963 34.9318 41.5043 34.5097C40.5997 33.5448 39.1525 33.2734 37.8559 33.4845C36.5594 33.6955 35.3834 34.3589 34.2376 35.0222C33.5742 35.4142 32.8807 35.8364 32.5188 36.5299C31.5539 38.3994 34.4486 41.3242 35.3532 42.7112C36.9815 45.1536 38.5493 47.6864 39.8158 50.4002Z"
                            fill="#333333" />
                        <path
                            d="M46.0574 46.5407C45.183 44.0078 44.0975 41.5654 43.0421 39.1231C42.4089 37.7059 41.7455 36.1982 40.5394 35.2032C40.1776 34.9017 39.7555 34.6303 39.273 34.6303C38.9413 34.6303 38.6398 34.7208 38.3383 34.8414C36.7402 35.4444 35.2025 36.1982 33.725 37.0425L33.6345 37.1028C35.5944 40.1181 37.4037 43.2238 39.0018 46.4502C39.7254 47.8975 40.4489 49.4052 41.655 50.4907C41.8058 50.6414 41.9869 50.7621 42.1979 50.8224C42.4392 50.8827 42.7104 50.8525 42.9818 50.7922C43.4642 50.7017 43.9468 50.5811 44.4292 50.4304C45.0624 50.2495 45.6956 50.0082 46.0876 49.4655C46.3891 49.0433 46.4796 48.5006 46.4494 47.988C46.3891 47.5357 46.2383 47.0533 46.0574 46.5407Z"
                            fill="white" />
                        <path
                            d="M44.5196 51.0937C44.0673 51.8174 45.2434 51.8777 45.5751 51.3349C46.0274 50.5811 44.8212 50.5811 44.5196 51.0937Z"
                            fill="white" />
                        <path
                            d="M24.8299 144.417C24.8299 144.417 20.1562 295.784 20.0657 319.303L31.3126 319.635C31.3126 319.635 43.585 200.561 52.9022 193.506C52.9022 193.506 56.7616 192.963 58.2391 193.506C59.7166 194.048 63.6064 311.403 61.1338 320.117H71.1144C71.1144 320.117 93.5179 178.881 92.1912 145.351C92.1912 145.351 93.0657 128.526 63.3953 130.034C33.7551 131.572 27.7849 127.712 24.8299 144.417Z"
                            fill="#273883" />
                        <path
                            d="M39.4238 14.1866C38.2177 17.413 38.0972 20.971 38.5796 24.3783C39.062 27.7856 40.1174 31.1024 41.2029 34.3589C42.8311 29.5344 44.4593 24.6497 47.1731 20.3378C48.4395 18.3176 50.0074 16.2069 49.9471 13.8248C49.9169 12.2267 49.0727 10.6588 47.746 9.75418C43.7658 6.98012 40.7807 10.6588 39.4238 14.1866Z"
                            fill="#5E5E5E" />
                        <path
                            d="M41.625 39.4547C41.7758 41.5956 41.8963 43.7967 42.62 45.7868C44.2181 50.2193 48.1983 52.6918 51.9674 54.6819C54.8621 56.1895 58.1788 57.6369 61.0735 56.2197C63.5762 54.9834 65.0235 52.0284 66.9533 49.7971C68.4006 48.1086 70.1797 46.7819 71.5668 45.0028C74.6725 41.0227 75.4262 35.2635 75.4262 29.8661C75.4564 23.0516 74.2503 15.6038 69.7575 11.2317C67.4056 8.94005 64.3904 7.76409 61.3751 7.07057C58.5407 6.40721 55.5857 6.16599 52.8116 7.04043C49.3742 8.12593 46.4494 10.9301 44.399 14.3374C39.4841 22.539 41.022 30.1375 41.625 39.4547Z"
                            fill="#67302D" />
                        <path
                            d="M84.231 14.1565C83.6581 10.8095 82.874 6.85951 79.7682 5.41218C77.8385 4.50759 75.5771 4.92974 73.4664 5.23127C71.416 5.5328 69.3353 5.68355 67.2849 5.41218C63.3048 4.83927 59.7769 2.57782 56.038 1.13048C52.2689 -0.286702 47.5952 -0.738998 44.5799 1.97476C42.7105 3.66331 41.8662 6.46753 42.5296 8.9099C42.9517 10.4779 43.7054 11.5634 44.6703 12.3473C43.5547 17.0512 51.8467 20.3077 55.9776 20.6695C59.6864 20.971 63.4254 20.3981 67.1342 19.8252C67.7976 25.1924 68.4308 30.5596 69.0942 35.957C69.5767 33.6654 70.6621 31.5245 72.1999 29.7757C73.2251 30.3787 73.5568 31.7356 73.406 32.9116C73.2553 34.0875 72.7125 35.173 72.2904 36.2585C70.7828 40.3291 71.1446 45.0631 68.9435 48.8322C69.4259 48.38 69.8781 47.8975 70.3002 47.3849C70.2701 47.6563 70.2702 47.9578 70.24 48.2292C75.6374 44.5204 80.0396 39.4246 82.9644 33.5749C84.1102 31.2833 85.0449 28.8409 85.3766 26.2779C85.8892 22.2073 84.9245 18.1668 84.231 14.1565Z"
                            fill="#5E5E5E" />
                        <path
                            d="M21.3925 154.97C21.3322 156.568 21.2718 158.227 21.905 159.704C23.473 163.353 31.8856 163.503 36.2276 164.257C51.0628 166.881 70.1496 169.293 85.2561 168.539C86.9146 168.449 88.6634 168.328 90.1107 167.604C92.6435 166.368 93.699 163.684 94.3925 161.212C97.7094 149.543 93.9703 135.914 94.0909 123.883C94.1814 113.993 94.2718 104.133 93.518 94.2726C92.7943 84.9252 91.2566 75.427 86.6432 66.9541C85.1959 64.3308 83.3867 61.7075 80.5825 60.1396C78.0497 58.7224 74.9439 58.3002 71.9286 58.0289C65.9885 57.5163 60.0183 57.5464 54.0782 58.0892C49.9473 58.4812 47.0224 57.9685 43.1026 57.3052C40.9919 56.9434 41.4743 56.4609 40.1175 57.9987C39.0319 59.2651 38.6098 61.7678 38.0671 63.2755C35.4739 70.3011 33.4235 77.5076 31.7952 84.7744C28.5086 99.3985 26.9407 114.234 25.9758 129.099C25.4933 137.331 21.7242 146.769 21.3925 154.97Z"
                            fill="#F2F2F2" />
                        <path
                            d="M52.6911 62.4613C54.048 62.5518 54.5908 64.4212 54.048 65.6575C53.5053 66.8938 52.3593 67.7381 51.4547 68.7633C49.4043 71.1152 48.8013 74.3415 48.2887 77.4171C46.7208 86.855 45.1528 96.2928 43.5547 105.731C43.0119 109.078 42.4391 112.455 42.6502 115.832C42.8612 119.209 43.8563 122.526 43.796 125.933C43.7658 126.838 43.6754 127.772 43.1327 128.526C42.7407 129.069 42.1376 129.461 41.5647 129.823C40.449 130.516 39.3334 131.24 38.2178 131.933C37.7052 132.235 37.1323 132.567 36.5594 132.416C35.9563 132.235 35.6849 131.602 35.323 131.089C34.7501 130.245 33.8757 129.672 33.2425 128.888C31.373 126.596 31.9158 123.249 32.549 120.355C33.9964 113.812 35.4437 107.238 36.891 100.695C39.0319 91.1367 41.2331 81.4877 45.5751 72.7133C46.2384 71.3866 46.9922 70.0297 47.2334 68.5522C47.4445 67.1953 46.4193 65.6575 46.6605 64.5419C47.0525 62.9136 51.2438 62.3709 52.6911 62.4613Z"
                            fill="#363636" />
                        <path
                            d="M145.501 127.35C150.055 126.928 154.909 126.566 159.01 128.647C163.111 130.697 165.855 136.125 163.473 140.045C161.754 142.849 158.226 143.844 155.03 144.567C122.947 151.744 89.9902 153.764 57.1839 155.754C54.7415 155.905 52.2388 156.026 49.9471 155.211C45.7559 153.704 41.3838 145.834 45.6957 142.276C47.8968 140.437 52.6308 140.497 55.3144 139.984C59.3851 139.23 63.4556 138.477 67.4961 137.783C83.9897 134.919 100.544 132.446 117.128 130.426C126.596 129.25 136.033 128.225 145.501 127.35Z"
                            fill="#EBEBEB" />
                        <path
                            d="M56.5505 145.502C55.1333 142.547 50.9722 140.256 48.2585 143.06C45.3337 146.045 47.6556 150.206 49.917 152.739C50.309 153.191 51.1231 152.588 50.7612 152.106C49.6456 150.598 48.4395 148.909 48.3792 146.98C48.2888 144.537 50.1582 142.638 52.6307 143.603C54.6208 144.387 55.4953 146.467 55.9174 148.427C56.0079 148.849 56.2793 149.663 56.1587 150.025L56.1887 150.447C55.7364 150.357 55.2843 150.266 54.832 150.176C54.3797 149.874 54.0178 149.06 53.8067 148.578C53.7765 148.487 53.264 147.04 53.264 147.04C54.4098 147.281 54.9223 145.502 53.7463 145.261C49.5852 144.417 52.6007 151.05 54.6511 152.015C55.7366 152.528 57.2744 152.407 57.6663 151.08C58.1186 149.513 57.2139 146.859 56.5505 145.502Z"
                            fill="#E0E0E0" />
                        <path
                            d="M115.62 115.5C122.163 110.646 129.279 105.489 137.42 105.399C141.28 105.369 145.773 107.178 146.496 110.947C147.22 114.807 143.723 118.033 140.587 120.355C122.012 134.044 102.413 146.346 84.7134 161.182C80.884 164.408 77.0846 167.755 72.5617 169.956C68.0689 172.157 62.6717 173.062 58.0282 171.162C54.5003 169.715 49.3742 164.318 51.4547 160.096C52.4196 158.136 55.5856 156.87 57.3044 155.784C60.1086 153.975 62.9431 152.166 65.7473 150.327C76.6928 143.15 87.5176 135.793 98.1616 128.194C104.011 124.033 109.831 119.812 115.62 115.5Z"
                            fill="#F2F2F2" />
                        <path
                            d="M87.7287 159.584C109.318 150.779 131.963 144.266 152.286 132.808C154.396 131.632 156.568 130.275 157.653 128.104C158.316 126.777 158.497 125.24 158.558 123.762C158.769 119.48 157.834 114.505 154.065 112.394C150.024 110.133 145.049 112.334 140.948 114.445C128.073 121.109 114.836 127.079 101.328 132.325C93.3974 135.401 85.3466 138.266 77.7783 142.155C70.6923 145.804 63.9984 150.387 56.4602 153.01C52.872 154.277 48.8013 155.272 46.6002 158.377C45.0925 160.518 44.7911 163.232 44.6102 165.855C44.4594 168.328 44.399 170.981 45.6352 173.122C49.5551 179.876 60.1389 171.916 64.6015 169.805C72.2905 166.217 79.9492 162.75 87.7287 159.584Z"
                            fill="#E8E8E8" />
                        <path
                            d="M55.3445 166.036C55.1636 163.805 54.4701 158.227 51.0628 158.739C47.8063 159.252 46.9016 165.373 46.8112 167.876C46.781 168.509 47.7459 168.479 47.7761 167.876C47.957 165.674 48.56 163.534 49.7963 161.694C52.4196 157.744 53.5957 164.559 53.6861 166.036C53.7464 167.001 53.7463 167.996 53.5654 168.931C53.5051 169.594 53.2941 170.197 52.9323 170.77C51.1533 171.615 50.3391 171.464 50.4597 170.318C49.917 168.78 50.6105 165.765 50.7914 164.197C50.9421 163.021 49.0727 163.021 48.9219 164.197C48.6807 166.368 47.2031 173.394 50.9119 173.725C54.8619 174.087 55.5555 168.75 55.3445 166.036Z"
                            fill="#D1D1D1" />
                        <path
                            d="M154.517 133.471C154.336 132.717 154.125 131.933 153.552 131.391C153.13 130.999 152.527 130.788 151.954 130.667C147.974 129.732 143.843 131.149 140.014 132.567C108.655 144.175 77.8386 157.352 47.746 171.976C46.4796 172.579 45.1832 173.243 44.2786 174.298C43.0122 175.776 42.7105 178.007 43.5548 179.786C44.399 181.535 46.2987 182.741 48.2586 182.711C49.3441 182.681 50.3996 182.319 51.4248 181.987C61.2245 178.58 70.9939 175.173 80.7936 171.765C98.6139 167.122 116.344 162.086 133.953 156.689C138.506 155.302 143.059 153.855 147.522 152.287C150.266 151.322 153.221 150.085 154.608 147.522C155.422 146.015 155.573 144.236 155.573 142.547C155.542 139.472 155.211 136.426 154.517 133.471Z"
                            fill="#F2F2F2" />
                        <path
                            d="M49.1632 177.615C48.6808 176.439 47.4745 175.052 46.0272 175.323C44.5196 175.625 45.1226 177.977 45.4241 178.942C45.5447 179.334 46.1478 179.183 46.0574 178.761C45.9669 178.369 45.9066 177.977 45.8765 177.555C45.8463 177.163 46.0273 176.439 46.5399 176.318C47.1128 176.198 47.8365 177.344 48.0476 177.736C48.3189 178.278 48.4697 178.881 48.5903 179.484C48.6506 179.756 48.8617 180.6 48.2285 180.449C47.9269 180.389 47.5951 179.394 47.5348 179.183C47.3238 178.52 46.2383 178.821 46.4796 179.484C46.8112 180.419 47.7157 182.258 49.0123 181.655C50.4596 180.992 49.5853 178.67 49.1632 177.615Z"
                            fill="#E0E0E0" />
                        <path
                            d="M82.271 70.1201C84.3817 69.3362 86.8845 68.9743 88.7238 70.2408C89.8093 70.9946 90.5028 72.1705 91.136 73.3465C93.4879 77.7187 95.6288 82.2114 97.7395 86.7344C100.302 92.1317 102.835 97.5291 105.398 102.926C107.087 106.484 108.775 110.042 109.801 113.842C112.695 124.456 110.283 135.703 107.871 146.407C106.454 152.709 105.036 159.041 102.745 165.102C102.534 165.614 102.292 166.187 101.81 166.458C101.388 166.67 100.905 166.639 100.453 166.609C99.1565 166.489 97.86 166.368 96.5634 166.247C95.9905 166.187 95.3573 166.097 95.0558 165.614C94.8146 165.192 94.905 164.679 95.0256 164.227C98.0409 151.472 99.9406 138.477 100.725 125.39C100.815 124.094 100.875 122.767 100.604 121.501C99.9708 118.546 97.6792 116.284 95.6891 113.993C88.573 105.821 84.4118 95.3882 77.0847 87.3977C74.6724 84.7744 71.1447 81.2767 72.8936 77.5076C74.4615 74.1003 78.8939 71.3564 82.271 70.1201Z"
                            fill="#EBEBEB" />
                        <path
                            d="M93.4275 170.981C92.9752 171.705 92.4927 172.61 92.9148 173.363C93.0656 173.635 93.3069 173.816 93.4275 174.087C93.6688 174.57 93.4576 175.173 93.5179 175.715C93.6385 176.56 94.4226 177.102 95.1463 177.525C95.7493 177.886 96.4427 178.218 97.1061 178.067C97.3775 178.007 97.6489 177.856 97.8901 177.705C99.2771 176.861 100.513 175.655 101.056 174.117C101.297 173.424 101.388 172.67 101.478 171.946C101.689 170.197 101.9 168.479 102.112 166.73C102.202 166.097 102.232 165.403 101.81 164.921C101.539 164.619 101.147 164.499 100.785 164.408C100.212 164.257 99.6088 164.107 99.0057 164.016C98.1916 163.895 97.2267 163.835 96.5634 164.438C96.0508 164.89 96.1112 165.463 95.9001 166.066C95.3573 167.785 94.3623 169.474 93.4275 170.981Z"
                            fill="#67302D" />
                        <path
                            d="M54.7716 320.509C54.1082 320.961 53.5353 321.534 52.691 321.806C51.2135 322.318 49.3441 321.776 47.8063 322.198C45.967 322.68 45.2733 324.218 44.8814 325.575C44.4592 326.992 44.2783 328.711 45.8463 329.676C46.8413 330.279 48.2285 330.399 49.5552 330.429C57.0934 330.761 64.7221 330.46 72.1698 329.525C72.9538 329.434 73.798 329.314 74.3709 328.892C74.974 328.47 75.1248 327.806 75.1851 327.203C75.5168 324.278 75.6372 318.157 70.1193 317.826C66.1391 317.584 57.6361 318.61 54.7716 320.509Z"
                            fill="#3D322B" />
                        <path
                            d="M19.5531 317.223C19.0104 317.162 18.4072 317.132 17.9247 317.283C16.7186 317.675 16.7186 318.911 16.0553 319.755C15.211 320.841 13.3114 321.203 11.5927 321.293C9.87402 321.384 8.06493 321.353 6.58744 322.017C4.53705 322.952 3.96414 324.912 3.75307 326.691C3.69276 327.083 3.6626 327.505 3.93398 327.867C4.35611 328.47 5.4114 328.65 6.31598 328.741C13.5225 329.525 20.9099 329.615 28.1466 329.012C29.805 328.862 31.6745 328.59 32.4584 327.535C33.7852 325.786 34.4486 321.142 32.3681 319.484C29.986 317.524 22.8699 317.223 19.5531 317.223Z"
                            fill="#3D322B" />
                        <defs>
                            <linearGradient id="paint0_linear" x1="0.166679" y1="325.938" x2="104.583" y2="331.078"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="white" />
                                <stop offset="1" />
                            </linearGradient>
                            <linearGradient id="paint1_linear" x1="0.166679" y1="325.938" x2="104.583" y2="331.078"
                                gradientUnits="userSpaceOnUse">
                                <stop />
                                <stop offset="1" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="welcome-elements">
                    <div className="welcome-text">
                        <h1 className="welcome-heading">We Understand Leasing</h1>
                        <p className="welcome-sub-heading">Let's make it work for you</p>
                    </div>
                    <Slide bottom>
                    <div className="cta-header">
                        <a href="/" className="yellow-button">Request a Quote</a>
                    </div>
                    </Slide>
                </div>
            </div>
            <div class="welcome-zagged">
            <svg width="142" height="81" viewBox="0 0 142 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M-5 4L19.1608 32L43.3217 4L67.4825 32L91.6434 4L115.822 32L140 4" stroke="#53BAE7"
                    stroke-width="4" stroke-miterlimit="10" />
                <path d="M-5 49L19.1608 77L43.3217 49L67.4825 77L91.6434 49L115.822 77L140 49" stroke="#53BAE7"
                    stroke-width="4" stroke-miterlimit="10" />
            </svg>
        </div>
            <section className="sub-heading">
                <div className="sub-heading-dots">
                    <svg width="87" height="293" viewBox="0 0 87 293" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path
                                d="M80.0269 177.095C76.6365 179.598 75.9168 184.375 78.4194 187.765C80.922 191.156 85.6991 191.875 89.0894 189.373C92.4798 186.87 93.1995 182.093 90.6969 178.703C88.1944 175.312 83.4172 174.593 80.0269 177.095Z"
                                fill="#FFE818" />
                            <path
                                d="M81.4521 127.669C78.0618 130.171 77.3421 134.948 79.8446 138.339C82.3472 141.729 87.1243 142.449 90.5147 139.946C93.905 137.444 94.6247 132.666 92.1222 129.276C89.6196 125.886 84.8425 125.166 81.4521 127.669Z"
                                fill="#FFE818" />
                            <path
                                d="M61.7651 152.355C58.3748 154.858 57.6551 159.635 60.1576 163.025C62.6602 166.415 67.4373 167.135 70.8277 164.633C74.218 162.13 74.9377 157.353 72.4351 153.963C69.9326 150.572 65.1554 149.853 61.7651 152.355Z"
                                fill="#FFE818" />
                            <path
                                d="M83.0258 78.4432C79.6354 80.9458 78.9157 85.7229 81.4183 89.1133C83.9209 92.5036 88.698 93.2233 92.0884 90.7207C95.4787 88.2182 96.1984 83.441 93.6958 80.0507C91.1933 76.6604 86.4161 75.9407 83.0258 78.4432Z"
                                fill="#FFE818" />
                            <path
                                d="M63.3388 103.13C59.9485 105.632 59.2288 110.409 61.7314 113.8C64.2339 117.19 69.0111 117.91 72.4014 115.407C75.7917 112.905 76.5114 108.127 74.0089 104.737C71.5063 101.347 66.7292 100.627 63.3388 103.13Z"
                                fill="#FFE818" />
                            <path
                                d="M43.6519 127.816C40.2615 130.319 39.5418 135.096 42.0444 138.486C44.547 141.877 49.3241 142.596 52.7144 140.094C56.1048 137.591 56.8245 132.814 54.3219 129.424C51.8194 126.033 47.0422 125.314 43.6519 127.816Z"
                                fill="#FFE818" />
                            <path
                                d="M84.5935 29.2097C81.2032 31.7123 80.4835 36.4894 82.9861 39.8798C85.4886 43.2701 90.2658 43.9898 93.6561 41.4872C97.0464 38.9847 97.7661 34.2075 95.2636 30.8172C92.761 27.4268 87.9839 26.7071 84.5935 29.2097Z"
                                fill="#FFE818" />
                            <path
                                d="M64.9125 53.9043C61.5222 56.4068 60.8025 61.184 63.305 64.5743C65.8076 67.9647 70.5847 68.6844 73.9751 66.1818C77.3654 63.6792 78.0851 58.9021 75.5825 55.5118C73.08 52.1214 68.3028 51.4017 64.9125 53.9043Z"
                                fill="#FFE818" />
                            <path
                                d="M45.2256 78.5907C41.8352 81.0932 41.1155 85.8704 43.6181 89.2607C46.1206 92.6511 50.8978 93.3708 54.2881 90.8682C57.6785 88.3656 58.3982 83.5885 55.8956 80.1982C53.393 76.8078 48.6159 76.0881 45.2256 78.5907Z"
                                fill="#FFE818" />
                            <path
                                d="M25.5386 103.277C22.1483 105.78 21.4286 110.557 23.9311 113.947C26.4337 117.338 31.2108 118.057 34.6012 115.555C37.9915 113.052 38.7112 108.275 36.2086 104.885C33.7061 101.494 28.9289 100.775 25.5386 103.277Z"
                                fill="#FFE818" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="137.25" height="261.69" fill="white"
                                    transform="translate(265.837 210.544) rotate(143.567)" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="sub-heading-blue-dot">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="10" cy="10" r="10" fill="#273883" />
                    </svg>
                </div>
                <div className="sub-heading-yellow-dot">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17" cy="17" r="17" fill="#FFE818" />
                    </svg>
                </div>
                <Slide bottom>
                <div className="sub-heading-contents">
                    <h2>
                        Rent assets short term, long term or till you own them; manage your vendors with our innovative lease
                        management tool that is uniquely designed for your business
                    </h2>
                    <div className="cta-header">
                        <a href="index.html" className="blue-button">Request a Demo</a>
                    </div>
                </div>
                </Slide>
            </section>

        </Style>
    )
}

export default HomeWelcome