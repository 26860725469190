import React from 'react';
import { Style } from './Style';
import Card from './Card';
import data from './data';


class About extends React.Component {
    constructor() {
        super()
        this.state = {
            display: data,
        }
    }
    render() {
        const { display } = this.state;
        return (
            <Style>
                <div className="welcome-elements">
                    <div className="contain">
                        <h1 className="welcome-heading">There is a new way!</h1>
                        <p className="welcome-sub-heading">This is what a new generation Leasing Company looks like</p>
                    </div>
                    <hr />
                    <div className="container">
                        <div className="gallery-container">
                            {display.map(images => <Card key={data.id} props={images}></Card>)}
                        </div>
                    </div>
                    <hr />
                    <div className="contain">
                        <p className="text">We are a technology empowered leasing company focused on serving businesses that drive impact.

                        Since 2015, we have through various channels aggregated and provided rental as well as lease services to dozens of companies across multiple industries, sectors and cities in Africa

                        This has been due to the effort of an amazing team of doers, respectable investors, dedicated partners and an advisory board worth over a century in experience combined.

                        Our ultimate ambition is to provide individually tailored financial and operational lease solutions across a range of asset classes to small, medium and large companies in the engineering and construction, healthcare, development, logistics and mobility sectors.
                        As a result of the relationships we have with OEMs and financial institutions in and out of Nigeria, we are able to provide competitive term financing for project specific assets in the form of lease financing as well as best rates and quick assembly or procurement time for equipment and vehicles.
                        In the case of operational leases and rentals, we provide real time support including replacement where applicable at no extra cost, with great uptime, quality, safety and speed of delivery at the core of our offer.

                        Our end to end vendor management software helps companies who deal with third party service providers efficiently manage such engagements. From recording attendance down to reconciliation, it ensures that your business can cut downtime, detect fraud and manage costs effectively.

                        Considering the needs of a growing segment of our clientele, on request, we offer lease advisory and asset efficiency audits to companies trying to determine what lease financing options works best for them, as well as businesses looking to improve their bottomline by renting over buying or earning more value off assets they own but underutilize.</p>
                    </div>
                </div>
                <div className="welcome-zagged">
                    <svg width="142" height="81" viewBox="0 0 142 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M-5 4L19.1608 32L43.3217 4L67.4825 32L91.6434 4L115.822 32L140 4" stroke="#53BAE7"
                            stroke-width="4" stroke-miterlimit="10" />
                        <path d="M-5 49L19.1608 77L43.3217 49L67.4825 77L91.6434 49L115.822 77L140 49" stroke="#53BAE7"
                            stroke-width="4" stroke-miterlimit="10" />
                    </svg>
                </div>

            </Style>
        )
    }
}

export default About