const data = [

    {
        id: 1,
        name: "Pencil",
        img: "assets/pictures/pexels-magda-ehlers-2547972.jpg",
    },
    {
        id: 2,
        name: "Pencil",
        img: "assets/pictures/pexels-pixabay-248747.jpg",
    },
    {
        id: 3,
        name: "Pencil",
        img: "assets/pictures/pexels-sarmad-mughal-305070.jpg",
    },
    {
        id: 4,
        name: "Pencil",
        img: "assets/pictures/pexels-tom-fisk-3057960.jpg",
    },
    ]
    export default data