import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import App from '../components/App';
import Home from '../pages/homepage/Home';
import Portfolio from '../pages/portfolio/Portfolio';
import Partners from '../pages/partners/Partners';
import Contact from '../pages/contact/Contact';
import About from '../pages/about/About';
import PrivacyPolicy from '../pages/privacy/privacypolicy';
import TermsAndCondition from '../pages/terms/termsandconditions';


import NoMatch from './NoMatch';

class ReactRouter extends React.Component {
    render (){
        return(
            <React.Fragment>
                <Router>
                    <App>
                        <Switch>
                            <Route exact path = '/' component = {Home} />
                            <Route exact path = '/portfolio' component = {Portfolio} />
                            <Route exact path = '/partners' component = {Partners} />
                            <Route exact path = '/contact' component = {Contact} />
                            <Route exact path = '/about' component = {About} />
                            <Route exact path = '/privacy' component = {PrivacyPolicy} />
                            <Route exact path = '/terms' component = {TermsAndCondition} />
                            <Route component={NoMatch} />   
                        </Switch>
                    </App>
                </Router>
            </React.Fragment>
        )
    }
}

export default ReactRouter